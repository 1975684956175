<template>
	<div class="layout-chat-left">
		<div class="add-box flex">
			<el-input class="mr4" v-model="addPath" size="large" placeholder="请输入邀请链接"></el-input>
			<el-button @click="createChat" size="large" type="primary"><i class="el-icon-plus"></i></el-button>
		</div>
		<div class="none-box" v-if="!_chatList.length > 0">暂无协助空间</div>
		<!-- <div class="pt10">
      <div class="chat-item" :class="{ active: item.chatLunciGuid === _chatingId }" v-for="item in _chatList"
        :key="item.guid">
        <div class="delete-icon" @click="onExit(item)"><i class="el-icon-delete" size="24"></i></div>
        <div class="content" @click="onItem(item)">
          <p class="user">请求用户：{{ item.lunciUser.nickname }}</p>
          <p class="user">协助人员：{{ item.helpUserCount }}</p>
          <p class="user">聊天名称：{{ item.lunciInfo.chatLunciTitle }}</p>
        </div>
      </div>
    </div> -->
		<div class="chats-panel pt10">
			<div
				:class="{ active: item.chatLunciGuid == _chatingId }"
				class="chat-item"
				v-for="item of _chatList"
				:key="item.guid"
			>
				<div class="chat-name-box" @click="onItem(item)">
					<div class="name">
						<span>{{ item.lunciInfo.chatLunciTitle }}</span>
					</div>
					<div class="date">{{ item.modifyTime }}</div>
				</div>
				<div class="chat-icon-box">
					<div class="opts">
						<el-popconfirm @confirm="onExit(item)" title="确定退出协作吗 ？">
							<a slot="reference"><i class="el-icon-delete"></i></a>
						</el-popconfirm>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { exitHelpLunci } from '@/apis/chat';
import { joinHelpLunci } from '@/apis/user';

export default {
	name: 'layout-cooperation-left',

	props: {
		_chatingId: String,
		_chatList: Array,
		_onChatState: Boolean,
	},

	data() {
		return {
			addPath: '',
		};
	},
	computed: {},
	mounted() {},
	methods: {
		// https://xy.deepcity.cn/app/chat?yqGuid=de094ea48df648449ade5ae3d7e6b9a9
		createChat() {
			if (this.addPath.trim().length === 0) {
				return;
			}
			let params = new URLSearchParams(this.addPath.split('?')[1]);
			if (params.get('yqGuid')) {
				this.joinLunci(params.get('yqGuid'));
			}
		},
		async joinLunci(guid) {
			let res = await joinHelpLunci({ chatLunciGuid: guid });
			if (res.code !== 0) {
				this.$message({
					type: 'info',
					message: res.msg || '获取协作聊天失败',
				});
				localStorage.setItem('yqGuid', '');
				return;
			}
			this.$emit('exitCooperation');
		},
		async onExit(item) {
			this.$confirm('是否退出该协助创作', '退出协助', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
			})
				.then(async () => {
					await exitHelpLunci({ chatLunciGuid: item.chatLunciGuid });
					this.$message({
						message: '退出成功',
						type: 'success',
					});
					this.$emit('exitCooperation');
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					});
				});
		},
		onItem(item) {
			// this.activeClassId = item.id;
			if (item.chatLunciGuid === this._chatingId) {
				return;
			}
			if (this._onChatState) {
				this.$emit('changeActiveId', item.chatLunciGuid);
			} else {
				this.$message({
					showClose: true,
					message: '有生成任务进行中，暂不可切换',
					type: 'warning',
				});
				return;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.layout-chat-left {
	// width: 250px;
	// flex-shrink: 0;
	// border-right: solid 1px var(--border-color);
	// padding: 10px;
	// background: rgb(250 251 255);
	// overflow-y: auto;
	// box-sizing: border-box;

	width: 250px;
	flex-shrink: 0;
	border-right: solid 1px var(--border-color);
	padding: 10px;
	background: rgb(250 251 255);
	background-color: #fff;
	overflow-y: auto;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	position: relative;

	.chats-panel {
		flex: 1;
		padding-bottom: 68px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.chat-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		// border: solid 1px var(--border-color);
		// background: white;
		background-color: #fafbfc;
		border-radius: 6px;
		margin-bottom: 8px;

		.chat-name-box {
			flex: 1;
			cursor: pointer;
		}

		.opts {
			color: var(--text-black-light);
		}

		.name {
			font-size: 15px;
			font-weight: bold;
			margin-bottom: 4px;
			color: var(--text-black);
		}

		.date {
			font-size: 12px;
			color: var(--text-black-light);
		}

		&.active,
		&:hover {
			//border: solid 1px var(--primary);
			// background: var(--primary-light);

			.name {
				// color: var(--primary);
				color: #4958ec;
			}

			.opts {
				color: #4958ec;
			}
		}
	}

	// .chat-item {
	//   padding: 10px 20px 10px 60px;
	//   border-bottom: 1px solid #e9e9e9;
	//   font-size: 14px;
	//   margin-top: 2px;
	//   position: relative;
	//   margin-bottom: 10px;
	//   border-radius: 5px;

	//   &.active {
	//     background-color: var(--primary-light);
	//     border: 1px solid var(--primary);
	//   }

	//   .delete-icon {
	//     width: 39px;
	//     height: 100%;
	//     position: absolute;
	//     left: 0px;
	//     top: 0px;
	//     display: flex;
	//     justify-content: center;
	//     align-items: center;
	//     border-right: 1px dashed #afafaf;
	//     cursor: pointer;
	//   }

	//   .content {
	//     cursor: pointer;

	//     p {
	//       overflow: hidden;
	//       /* 确保超出容器的文本被隐藏 */
	//       white-space: nowrap;
	//       /* 防止文本换行 */
	//       text-overflow: ellipsis;
	//       /* 超出部分显示省略号 */
	//     }
	//   }
	// }
}

.none-box {
	font-size: 16px;
	padding: 30px;
}

@media screen and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
	.layout-chat-left {
		width: 200px;

		.chat-item {
			padding: 4px;
			margin-bottom: 40px;
			position: relative;

			.name {
				font-size: 12px;
			}

			.chat-icon-box {
				position: absolute;
				bottom: -28px;
				left: 0px;
				width: 100%;
			}

			.opts {
				width: 100%;
				display: flex;
				justify-content: space-around;
			}
		}
	}
}
</style>

import { service as request, service_xg } from '@/libs/request'


// 创建图片订单
export async function createImgOrder (dat) {
  let { model, copywritingCategoryId, userPrompt, imageSize, imageNum, imgUrl, isOpenProfessional } = dat
  return await request.post('api.userWork/createImgOrder', {
    model,
    copywritingCategoryId,
    userPrompt,
    imageSize,
    imageNum,
    imgUrl,
    isOpenProfessional
  })
}

export async function getDrawStyles () {
  let { data } = await request.post('square/api.ContentGeneration/categoryContentList', { cateId: 6 })
  return data
}

export async function getExampleInfo (params = {}) {
  let { data } = await request.post('user/api.userWork/exampleInfo', params)
  return data
}

export async function generateImage (params) {
  let res = await service_xg.post('user/api.userWork/createImgOrder', params)
  return res
}

export async function getGenerateImage (orderNo) {
  let { data } = await request.post('user/api.userWork/imgOrderInfo', { orderNo })
  return { images: data.imageResult }
}

export async function getWorkImages (page = 1, pageSize = 50, workType = 'image') {

  let { data } = await request.post('user/api.userWork/workRecord', { page, pageSize, workType })
  let records = []

  for (let { guid, workResult, workParams, orderNo, workStatus } of data.data) {

    records.push({ guid, img: workResult[0], title: workParams.title, orderNo, workStatus })

    // if (workResult[0]) {
    //     records.push({guid, img: workResult[0], title: workParams.title, orderNo,})
    // }

  }
  return records
}

export async function queryDrawOrderChange (orderNo) {

  let { data } = await request.post('user/api.userWork/imgOrderInfo', { orderNo })

  let imgChangeInfo = data.imgChangeInfo

  if (imgChangeInfo && imgChangeInfo != []) {
    let ableU_items = imgChangeInfo?.able_U?.components
    let ableV_items = imgChangeInfo?.able_V?.components
    return { ableU_items, ableV_items }
  } else {
    return null
  }
}

// 图片变化
export async function createImgOrderChange (orderId, customId) {
  let { data } = await service_xg.post('user/api.userWork/createImgOrderChange', {
    oldImgOrderSn: orderId,
    custom_id: customId
  })
  return data
}

// 图片变大
export async function createImgOrderUpscale (orderId, customId) {
  let { data } = await service_xg.post('user/api.userWork/createImgOrderUpscale', {
    oldImgOrderSn: orderId,
    custom_id: customId
  })
  return data
}
// 删除用户记录
export function recordDelApi (params) {
  return request.post('/user/api.userWork/recordDel', params)
}
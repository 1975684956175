<template>
	<div class="container">
		<div class="layout-chat-main">
			<div class="chat-header" v-if="_chatObj">
				<!-- <span>{{ _chatObj.chatLunciTitle }}</span> -->
				<div class="left-box">
					<div @click="onInvitation" class="ml10 invitation-btn top-public-btn" v-if="!xzConfig.isHelp">
						<i class="iconfont el-icon-share mr4"></i> 邀请协助
					</div>
					<div class="ml10 cooperation-btn top-public-btn" v-if="xzConfig.isHelp">
						<img class="icon" src="../../../static/icon/chat-header-xzz-icon.png" /> 协助中
					</div>
					<div
						class="ml10 cooperation-pop-btn top-public-btn"
						@click="reqXzUserListConfig.isShowPop = true"
						v-if="!xzConfig.isHelp"
					>
						协作联盟
					</div>
				</div>
				<div class="center-box" v-if="xzConfig.isHelp">
					<el-popconfirm title="确认结束协助，是否继续？" @confirm="onChangeXzStatus('end')">
						<div class="end-btn top-public-btn ml10" slot="reference">
							<img class="icon" src="../../../static/icon/chat-header-end-on-icon.png" />结束
						</div>
					</el-popconfirm>
					<el-popconfirm title="确认返回协助，，是否继续？" @confirm="onChangeXzStatus('return')">
						<div
							class="back-btn top-public-btn ml10"
							:class="{ active: !xzConfig.helpInfo.isAbleReturn }"
							slot="reference"
						>
							<img
								class="icon"
								v-if="xzConfig.helpInfo.isAbleReturn"
								src="../../../static/icon/chat-header-back-on-icon.png"
							/>
							<img class="icon" v-else src="../../../static/icon/chat-header-back-icon.png" />
							返回
						</div>
					</el-popconfirm>
				</div>
				<div class="right-box">
					<el-dropdown trigger="hover" class="user-dropdown" v-if="xzConfig.isHelp">
						<div class="xz-logo-box">
							<img class="img" :src="xzConfig.helpInfo.helpUser.avatarUrl" />
						</div>
						<el-dropdown-menu slot="dropdown">
							<div class="xz-user-dropdown-menu">
								<div class="logo-box">
									<div class="logo">
										<img class="img" :src="xzConfig.helpInfo.helpUser.avatarUrl" />
									</div>
									<div class="name-box">
										<div class="name">{{ xzConfig.helpInfo.helpUser.nickname }}</div>
										<div class="label">{{ xzConfig.helpInfo.helpUser.introduction }}</div>
									</div>
								</div>
								<div class="count">已协助次数：{{ xzConfig.helpInfo.debugChatCount }}</div>
							</div>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<chat-box id="chatbox" ref="chatBox" :_chatDialogs="_chatDialogs" @onPause="onPause"></chat-box>
			<div class="chat-input">
				<div class="opt-panel">
					<div class="popper-box mr4" ref="aiPopRef">
						<div class="dropdown-box" v-if="modelPopCofig.aiPop">
							<div
								:class="['item', { active: modelPopCofig.modelVendorSign == item.sign }]"
								v-for="item in aiModels"
								:key="item.sign"
								@click="onModelChange(item)"
							>
								{{ item.name }} <i class="el-icon-arrow-right el-icon--right ml-10"></i>
							</div>
						</div>
						<div class="children-dropdown-box" v-if="modelPopCofig.aiPop">
							<div class="tab">
								<div
									class="item"
									:class="{ active: modelPopCofig.modelType === 'text' }"
									@click="onModelTypeChange('text')"
								>
									文字
								</div>
								<div
									class="item"
									:class="{ active: modelPopCofig.modelType === 'image' }"
									@click="onModelTypeChange('image')"
								>
									图片
								</div>
								<div
									class="item"
									:class="{ active: modelPopCofig.modelType === 'video' }"
									@click="onModelTypeChange('video')"
								>
									视频
								</div>
							</div>
							<div class="content">
								<div
									class="item"
									:class="{ active: modelPopCofig.guid === item.guid }"
									v-for="item in aiModelChildren"
									@click="onModelChoose(item)"
								>
									<div class="name">{{ item.modelName }}</div>
									<div class="price">价格：{{ item.usePrice == 0 ? '免费' : item.usePrice }}</div>
									<el-tooltip effect="light" :content="item.modelDesc" placement="top-start">
										<div class="specific">特点：{{ item.modelDesc }}</div>
									</el-tooltip>
								</div>
								<div class="empty" v-if="aiModelChildren.length === 0 || modelPopCofig.isTabLoading">
									<p v-if="modelPopCofig.isTabLoading">加载中...</p>
									<p v-else="aiModelChildren.length === 0">暂无可用模型</p>
								</div>
							</div>
						</div>
						<!-- <iclass="el-icon-arrow-down el-icon--right"></i> -->
						<div :class="['model-public-btn', { active: modelPopCofig.aiPop }]" @click="onModelBtn">
							选择AI模型：{{ modelPopCofig.modelName }}
						</div>
					</div>

					<!-- <el-dropdown trigger="click" class="mr4">
            <el-button size="mini" round class="el-dropdown-link" style="padding: 6px 10px; margin-bottom: 0">
              <i class="iconfont icon-setting mr4"></i> 选择AI模型 ：{{ selectModel.name }}
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="choooseModel(value)" v-for="{ name, value } of models" :key="value">{{
                name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->

					<el-dropdown trigger="click" class="mr4">
						<div class="role-select-btn">
							选择AI角色<span v-if="selectRole.type === 'base'">：{{ selectRole.name }}</span>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="item of baseRoles" :key="item.id">
								<div class="flex role-drop-box">
									<div class="role-drop-title mr-4" @click="choooseRole(item, 'base')">
										{{ item.name }}
									</div>
								</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-dropdown trigger="click" class="mr4">
						<!-- <el-button round class="el-dropdown-link" style="padding: 6px 10px; margin-bottom: 0">
              <i class="iconfont icon-robot2 mr4"></i> 选择协作角色<span v-if="selectRole.type === 'xz'">：{{ selectRole.name
                }}</span>
            </el-button> -->
						<div class="role-select-btn" @click="onXzRole">
							选择协助角色<span v-if="selectRole.type === 'xz'">：{{ selectRole.name }}</span>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="item of roles" :key="item.id">
								<div class="flex role-drop-box">
									<div class="role-drop-title mr-4" @click="choooseRole(item, 'xz')">
										{{ item.name }}
									</div>
									<!-- <el-button
										size="mini"
										icon="el-icon-edit"
										v-if="item.isEdit"
										@click="onEditRole(item)"
									></el-button>
									<el-button
										size="mini"
										icon="el-icon-delete"
										v-if="item.isDelete"
										@click="onDeleteRole(item)"
									></el-button> -->
								</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>

					<!-- <el-button @click="cleanDialogs" size="mini" round class="mr4">
            清除聊天记录
          </el-button> -->
					<div class="clean-chat-btn mr4" @click="cleanDialogs">清除聊天记录</div>

					<!--                <el-upload class="ml4 mr16" :show-file-list="false" name="img"-->
					<!--                           action="https://ai-v2.deepcity.cn/user/api.userinfo/uploadImg"-->
					<!--                           :on-success="uploadSuccess" :before-upload="beforeUpload" :limit="3"-->
					<!--                           :on-exceed="handleExceed">-->
					<!--                    <el-badge :value="msgImgsCount" v-if="msgImgsCount>0">-->
					<!--                        <el-button :disabled="!isSupportImg" @click="" size="mini" round style="padding: 5px 10px;">-->
					<!--                            <i class="el-icon-upload2"></i> 图像-->
					<!--                        </el-button>-->
					<!--                    </el-badge>-->
					<!--                    <el-button  :disabled="!isSupportImg" @click="" size="mini" round style="padding: 5px 10px;" v-if="msgImgsCount==0">-->
					<!--                        <i class="el-icon-upload2"></i> 图像-->
					<!--                    </el-button>-->
					<!--                </el-upload>-->

					<div
						class="clean-chat-btn mr4"
						@click="onShowPop('img')"
						:disabled="modelDetail.isImageRecognition == 0"
						v-if="msgImgsCount == 0 && modelDetail.isImageRecognition == 1"
					>
						<i class="el-icon-upload2"></i> 上传图像
					</div>
					<el-badge :value="msgImgsCount" v-if="msgImgsCount > 0" class="ml4">
						<div
							class="clean-chat-btn mr4"
							@click="onShowPop('img')"
							:disabled="modelDetail.isImageRecognition == 0"
						>
							<i class="el-icon-upload2"></i> 上传图像
						</div>
					</el-badge>
					<div
						class="clean-chat-btn mr4"
						@click="onShowPop('video')"
						:disabled="modelDetail.isVideoRecognition == 0"
						v-if="msgVideoCount == 0 && modelDetail.isVideoRecognition == 1"
					>
						<i class="el-icon-upload2"></i> 上传视频
					</div>
					<el-badge :value="msgVideoCount" v-if="msgVideoCount > 0" class="ml4">
						<div
							class="clean-chat-btn mr4"
							@click="onShowPop('video')"
							:disabled="modelDetail.isVideoRecognition == 0"
						>
							<i class="el-icon-upload2"></i> 上传视频
						</div>
					</el-badge>
					<!-- <el-button type="success" style="margin-left: auto" class="ml4" size="mini" @click="waitingImage">
            <i class="el-icon-picture mr4"></i> 绘画
          </el-button> -->
				</div>
				<div class="chat-input-box">
					<div class="input-box">
						<el-input
							type="textarea"
							:autosize="{ minRows: 2, maxRows: 12 }"
							:placeholder="reqAiConfig.placeholder"
							v-model="textarea"
							resize="none"
						></el-input>
						<!-- <el-button
							size="mini"
							round
							class="el-dropdown-link"
							style="padding: 6px 10px; margin-bottom: 0"
							@click="isPrviewShow = true"
						>
							<i class="el-icon-full-screen"></i> 预览
						</el-button> -->
					</div>
					<div class="send-icon">
						<img class="icon" @click="send" src="../../../static/icon/send-icon.png" />
					</div>
				</div>
			</div>
			<el-dialog title="图像上传" :visible.sync="reqXzUserListConfig.isShowPop" width="1000px">
				<div class="xz-users-dialog">
					<div class="content">
						<div class="item" v-for="item in reqXzUserListConfig.list" :key="item.guid">
							<div class="top-box">
								<div class="logo">
									<img class="img" :src="item.avatarUrl" />
								</div>
								<div class="name-box">
									<p class="name">{{ item.nickname }}</p>
									<div class="yq-btn" @click="onInvite(item.guid)">请他协助我</div>
								</div>
							</div>
							<div class="lable">
								{{ item.introduction }}
							</div>
						</div>
					</div>
					<el-pagination
						background
						layout="prev, pager, next"
						:total="reqXzUserListConfig.total"
						:current-page="reqXzUserListConfig.page"
						@current-change="handlePageChang"
						class="mt20"
					>
					</el-pagination>
				</div>
			</el-dialog>
			<el-dialog title="图像上传" :visible.sync="imgInputVisible" width="500px">
				<div class="center">
					<el-upload
						ref="imgRef"
						name="img"
						list-type="picture"
						:limit="3"
						:on-success="uploadSuccess"
						:before-remove="beforeRemove"
						:on-exceed="handleExceed"
						drag
						action="https://ai-api.deepcity.cn/user/api.userinfo/uploadImg"
					>
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过5mb</div>
					</el-upload>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="imgInputVisible = false">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="视频上传" :visible.sync="videoInputVisible" width="500px">
				<div class="center">
					<el-upload
						ref="videoRef"
						name="video"
						list-type="text"
						:limit="1"
						:on-success="videoUploadSuccess"
						:before-remove="videoBeforeRemove"
						accept=".mp4"
						:on-exceed="handleExceed"
						drag
						action="https://ai-api.deepcity.cn/user/api.userinfo/uploadVideo"
					>
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip">请上传MP4文件并且大小不超过100M的视频文件</div>
					</el-upload>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="videoInputVisible = false">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="预览" :visible.sync="isPrviewShow" width="800px">
				<el-input
					type="textarea"
					:rows="20"
					placeholder="请输入参数（输入后请点击保存）"
					v-model="systemPrompt"
				></el-input>
				<div class="prompt-btn-box">
					<el-button type="primary" @click="onSavePrompt">保 存</el-button>
					<el-button type="info" @click="isPrviewShow = false">取 消</el-button>
				</div>
			</el-dialog>
		</div>
		<div class="options-box">
			<div class="param-box">
				<div class="label">参数设置</div>
				<div class="title mt10">system_prompt</div>
				<div class="input-box mt10">
					<el-input
						class="inp"
						type="textarea"
						:rows="2"
						placeholder="请输入参数"
						v-model="reqAiConfig.systemPrompt"
						resize="none"
					></el-input>
					<el-button size="mini" round class="el-dropdown-link" style="padding: 2px 6px; margin-bottom: 0">
						<i class="el-icon-full-screen"></i>
					</el-button>
					<div class="zw-box" @click="onOpenSavePop"></div>
				</div>
			</div>
			<div class="slider-box">
				<div class="item">
					<div class="title">
						max_tokens
						<el-tooltip class="item" effect="light" content="最大输出token数" placement="top-start"
							><i class="el-icon-warning-outline"></i
						></el-tooltip>
					</div>
					<div class="slider">
						<div class="label">{{ reqAiConfig.maxOutputTokens }}</div>
						<el-slider
							v-model="reqAiConfig.maxOutputTokens"
							:max="modelDetail.maxOutputTokens"
							:step="1"
						></el-slider>
					</div>
				</div>
				<div class="item">
					<div class="title">
						temperature
						<el-tooltip
							class="item"
							effect="light"
							content="采样温度，控制输出的随机性，取值范围是：[0.0, 1.0]"
							placement="top-start"
							><i class="el-icon-warning-outline"></i
						></el-tooltip>
					</div>
					<div class="slider">
						<div class="label">{{ reqAiConfig.temperature }}</div>
						<el-slider v-model="reqAiConfig.temperature" :step="0.1" :min="0" :max="1"></el-slider>
					</div>
				</div>
				<div class="item">
					<div class="title">
						top_p
						<el-tooltip
							class="item"
							effect="light"
							content="温度取样的另一种方法，取值范围是：[0.0, 1.0]"
							placement="top-start"
							><i class="el-icon-warning-outline"></i
						></el-tooltip>
					</div>
					<div class="slider">
						<div class="label">{{ reqAiConfig.topP }}</div>
						<el-slider v-model="reqAiConfig.topP" :step="0.1" :min="0" :max="1"></el-slider>
					</div>
				</div>
			</div>
			<div class="switch-box">
				<div class="label">工具调用</div>
				<div class="item">
					<div class="t">网页检索</div>
					<div class="switch">
						<el-switch
							v-model="modelDetail.isNetwork"
							:activeValue="1"
							:inactiveValue="0"
							active-color="#6126F5"
							inactive-color="#DEE0E4"
							disabled
						></el-switch>
					</div>
				</div>
				<!-- <div class="item">
          <div class="t">知识库检索</div>
          <div class="switch">
            <el-switch v-model="value" active-color="#6126F5" inactive-color="#DEE0E4"></el-switch>
          </div>
        </div>
        <div class="item">
          <div class="t">函数调用</div>
          <div class="switch">
            <el-switch v-model="value" active-color="#6126F5" inactive-color="#DEE0E4"></el-switch>
          </div>
        </div> -->
			</div>
			<div class="switch-box">
				<div class="item">
					<div class="t">多轮对话</div>
					<div class="switch">
						<el-switch
							v-model="reqAiConfig.isMultiple"
							active-color="#6126F5"
							inactive-color="#DEE0E4"
						></el-switch>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ChatBox from '@/views/app/layout-chat/chat-box.vue';
import {
	clearLunci,
	genImage,
	genVideoApi,
	queryGenVideoApi,
	getAbleModels,
	getRobotRoles,
	saveChatDialog,
	helpRoleList,
	getAiVendorListApi,
	getAiVendorModelListApi,
	chatLunciHelpInfoApi,
	lunciHelpUserListApi,
	lunciSelectHelpUserApi,
	lunciOwnerUpdateStatusApi,
	lunciPromptSaveApi,
} from '@/apis/chat';

export default {
	name: 'layout-chat-main',
	components: { ChatBox },

	async created() {
		this.models = await getAbleModels({ is_all_pc: 1 });
		this.baseRoles = await getRobotRoles();
		this.models.push({ name: '通用AI模型', value: '' });
		this.baseRoles.push({ name: '通用对话角色', value: '', id: 0 });
		this.getHelpRoleList();
		//获取ai模型
		this.getAiVendorList();

		//协作者联盟用户列表
		this.getLunciHelpUserList();
		document.addEventListener('click', this.handleClickOutside);
	},

	props: {
		_chatDialogs: Array,
		_chatingId: String,
		_chatObj: Object,
	},

	data() {
		return {
			models: [],
			baseRoles: [],
			roles: [],
			selectRoleKey: 0,
			selectRole: { id: '', name: '通用对话角色', type: 'base' },
			msg: [],
			isNewGlobalMsg: false,
			textarea: '',
			// 图片
			msgImgs: [],
			videos: [],
			imgInputVisible: false,
			videoInputVisible: false,
			isPrviewShow: false,
			isHelp: false,
			xzConfig: {
				isHelp: false,
				helpInfo: {
					helpGuid: '',
					helpUser: {
						nickname: '',
						introduction: '',
						avatarUrl: '',
					},
					isAbleReturn: false,
					debugChatCount: 0,
				},
			},
			modelPopCofig: {
				aiPop: false,
				modelVendorSign: '',
				modelType: 'text',
				modelName: '获取中...',
				guid: '',
				isFirst: true,
				isTabLoading: false,
			},
			reqAiConfig: {
				msgId: '',
				roleId: '',
				content_cate_id: '',
				aiModelGuid: '',
				roleType: '',
				chatScene: 'default',
				sceneValue: '',
				maxOutputTokens: 0,
				temperature: 0,
				topP: 0,
				systemPrompt: '',
				isMultiple: true,
				isSend: false,
				placeholder: '请输入您的问题或需求',
			},
			systemPrompt: '',
			modelDetail: {
				maxOutputTokens: 0,
				modelType: 'text',
				isImageRecognition: 0,
				isVideoRecognition: 0,
			},
			aiModels: [],
			aiModelChildren: [],
			source: null,
			reqXzUserListConfig: {
				pageSize: 6,
				page: 1,
				total: 0,
				list: [],
				isShowPop: false,
			},
		};
	},

	computed: {
		msgImgsCount() {
			return this.msgImgs.length;
		},
		msgVideoCount() {
			return this.videos.length;
		},
	},
	watch: {
		async _chatingId() {
			//聊天轮次协助详情
			this.getChatLunciHelpInfo();
			this.getHelpRoleList();
			this.reqAiConfig.systemPrompt = this._chatObj.chatLunciPrompt;
		},
	},

	methods: {
		//保存参数
		async onSavePrompt() {
			await lunciPromptSaveApi({
				chatLunciGuid: this._chatingId,
				chatLunciPrompt: this.systemPrompt,
			});
			this.$message({
				message: '保存成功',
				type: 'success',
			});
			this.reqAiConfig.systemPrompt = this.systemPrompt;
			this.isPrviewShow = false;
		},
		onOpenSavePop() {
			this.systemPrompt = this.reqAiConfig.systemPrompt;
			this.isPrviewShow = true;
		},
		async onChangeXzStatus(status) {
			if (status === 'end') {
				try {
					let res = await lunciOwnerUpdateStatusApi({
						chatLunciGuid: this._chatingId,
						status: status,
					});
					if (res.code == 0) {
						this.$message({
							message: '结束协助',
							type: 'success',
						});
					} else {
						this.$message({
							message: res.msg,
							type: 'info',
						});
					}
					this.getChatLunciHelpInfo();
				} catch (error) {
					this.$message({
						message: error.msg,
						type: 'info',
					});
				}
			} else {
				if (this.xzConfig.helpInfo.isAbleReturn) {
					try {
						let res = await lunciOwnerUpdateStatusApi({
							chatLunciGuid: this._chatingId,
							status: status,
						});
						if (res.code == 0) {
							this.$message({
								message: '返回成功',
								type: 'success',
							});
						} else {
							this.$message({
								message: res.msg,
								type: 'info',
							});
						}
						this.getChatLunciHelpInfo();
					} catch (error) {
						this.$message({
							message: error.msg,
							type: 'info',
						});
					}
				}
			}
		},
		//弹窗邀请协作
		async onInvite(guid) {
			try {
				let res = await lunciSelectHelpUserApi({
					chatLunciGuid: this._chatingId,
					helpUserGuid: guid,
				});
				if (res.code == 0) {
					this.$message({
						message: '邀请成功',
						type: 'success',
					});
					this.reqXzUserListConfig.isShowPop = false;
					this.getChatLunciHelpInfo();
				} else {
					this.$message({
						message: res.msg,
						type: 'info',
					});
				}
			} catch (error) {
				this.$message({
					message: error.msg,
					type: 'info',
				});
			}
		},
		//协作者联盟用户列表
		async getLunciHelpUserList() {
			let res = await lunciHelpUserListApi(this.reqXzUserListConfig);
			this.reqXzUserListConfig.list = res.data.data;
			this.reqXzUserListConfig.total = res.data.total;
		},
		async handlePageChang(page) {
			this.reqXzUserListConfig.page = page;
			getLunciHelpUserList();
		},
		//聊天轮次协助详情
		async getChatLunciHelpInfo() {
			let res = await chatLunciHelpInfoApi({ chatLunciGuid: this._chatingId });
			if (res.code === 0) {
				this.xzConfig.isHelp = res.data.isHelp;
				if (res.data.isHelp) {
					this.xzConfig.helpInfo = res.data.helpInfo;
				}
			}
		},
		//点击隐藏模型弹窗
		handleClickOutside(event) {
			if (!this.$refs.aiPopRef.contains(event.target)) {
				this.modelPopCofig.aiPop = false;
			}
		},
		uploadSuccess({ code, data }) {
			if (code == 0) {
				this.msgImgs.push(data);
			}
		},
		async getHelpRoleList() {
			let res = await helpRoleList({ chatLunciGuid: this._chatingId });
			let list = [];
			this.helpList = res.data;
			res.data.forEach(item => {
				let obj = {
					id: item.sysId,
					name: item.showRoleName,
					isHelp: true,
					isDelete: item.isDelete,
					isEdit: item.isEdit,
				};
				list.push(obj);
			});
			// this.roles = this.baseRoles.concat(list);
			this.roles = list;
		},
		onInvitation() {
			let guid = this._chatObj.guid;
			let yqUrl = `https://xy.deepcity.cn/app/chat?yqGuid=${guid}`;
			this.$copyText(yqUrl);
			this.$message({ message: '已复制邀请链接', type: 'success' });
		},

		beforeRemove({ response }) {
			this.msgImgs = this.msgImgs.filter(item => item != response.data);
		},

		//移除视频文件
		videoBeforeRemove({ response }) {
			this.videos = this.videos.filter(item => item != response.data);
		},
		//上传视频
		videoUploadSuccess({ code, data }) {
			if (code == 0) {
				this.videos.push(data);
			}
		},
		handleExceed() {
			alert('超过文件上传数量');
		},

		choooseRole(role, type) {
			//type xz为协助角色  base为 默认ai角色
			if (role.isHelp) {
				this.isHelp = true;
			}
			this.selectRoleKey = role.id;
			if (type === 'xz') {
				this.selectRole = this.roles.find(item => item.id == role.id);
			} else {
				this.selectRole = this.baseRoles.find(item => item.id == role.id);
			}
			this.selectRole.type = type;
		},
		onXzRole() {
			if (this.roles.length === 0) {
				this.$message({ message: '暂无可协助角色', type: 'info' });
			}
		},

		cleanDialogs() {
			// this._chatDialogs = []
			this.$emit('clearChatDialogs');
			clearLunci(this._chatingId);
		},
		onShowPop(type) {
			if (type === 'video') {
				this.videoInputVisible = true;
				if (this.videos.length === 0) {
					this.$nextTick(() => {
						this.$refs.videoRef.clearFiles();
					});
				}
			}
			if (type === 'img') {
				this.imgInputVisible = true;
				if (this.msgImgs.length === 0) {
					this.$nextTick(() => {
						this.$refs.imgRef.clearFiles();
					});
				}
			}
		},

		async send() {
			if (!this.reqAiConfig.isSend) {
				this.$message({ message: 'AI模型错误或正在生成中，请稍后重试...', type: 'warning' });
				return;
			}
			if (!this.textarea) {
				return;
			}
			if (this.modelDetail.modelType === 'image') {
				this.waitingImage();
				return;
			}
			if (this.modelDetail.modelType === 'video') {
				this.waitingVideo();
				return;
			}
			let content = this.textarea;
			this.textarea = '';
			let lastMsgId = '';
			let len = this._chatDialogs.length;
			if (len > 0) {
				lastMsgId = this._chatDialogs[len - 1].msgId;
			}

			let saveMsgId = await saveChatDialog({
				role: 'user',
				content,
				lastMsgId: this.reqAiConfig.isMultiple ? lastMsgId : '',
				aiModelGuid: this.modelPopCofig.guid,
				chatLunciGuid: this._chatingId,
				imgs: this.msgImgs,
				videos: this.videos,
			});

			this._chatDialogs.push({
				msgId: 'new',
				chatRole: 'user',
				chatContent: content,
				imgUrls: this.msgImgs,
				videoUrls: this.videos,
			});
			this._chatDialogs.push({
				msgId: 'new',
				chatRole: 'assistant',
				chatContent: '',
				starting: true,
				finished: false,
			});

			this.msgImgs = [];
			this.videos = [];

			this.toBottom();

			await this.waitChat(saveMsgId);

			return false;
		},

		// 滚动到最底部
		toBottom() {
			this.$nextTick(() => {
				let scrollDom = document.getElementById('chatbox');
				scrollDom.scrollTop = scrollDom.scrollHeight;
			});
		},

		async waitChat(lastMsgId) {
			let dialog = this._chatDialogs[this._chatDialogs.length - 1];
			let self = this;
			// let link = `https://ai-v2.deepcity.cn/square/api.chat/sendOpen?roleId=${this.selectRoleKey}&aiModelGuid=${this.modelPopCofig.guid}&msgId=${lastMsgId}&roleType=${this.isHelp ? '2' : '1'}&maxOutputTokens=${this.reqAiConfig.maxOutputTokens}&temperature=${this.reqAiConfig.temperature}&topP=${this.reqAiConfig.topP}`;
			let link = `https://ai-api.deepcity.cn/square/api.chat/sendOpen?roleId=${this.selectRoleKey}&aiModelGuid=${
				this.modelPopCofig.guid
			}&msgId=${lastMsgId}&roleType=${this.isHelp ? '2' : '1'}&maxOutputTokens=${
				this.reqAiConfig.maxOutputTokens
			}&temperature=${this.reqAiConfig.temperature}&topP=${this.reqAiConfig.topP}`;

			this.source = new EventSource(link);
			this.source.onmessage = async function ({ data }) {
				data && (dialog.starting = false);

				if (data == '[DONE]') {
					dialog.finished = true;
					dialog.msgId = await saveChatDialog({
						role: 'assistant',
						content: dialog.chatContent,
						aiModelGuid: self.modelPopCofig.guid,
						lastMsgId: self.reqAiConfig.isMultiple ? lastMsgId : '',
						chatLunciGuid: self._chatingId,
						model: self.selectModelKey,
					});
					self.source.close();
					return;
				} else {
					dialog.chatContent += data.replace(/\\n/g, '\n');
				}
				// console.log(dialog.chatContent, '-----------------aaa')
				// if (data) {
				//   if (dialog.chatContent == '') {
				//     dialog.chatContent = data.replace(/^\n+/, '')
				//   } else {
				//     dialog.chatContent += data
				//   }
				//   console.log(dialog.chatContent, 'dialog.chatContentdialog.chatContent')
				// }
			};

			this.source.onopen = function (event) {
				console.log('Connection was opened');
				// dialog.starting = false
			};

			this.source.onerror = async function (event) {
				// dialog.chatContent += '[ ERROR ]'
				dialog.finished = true;
				self.source.close();
			};
		},
		onPause() {
			let dialog = this._chatDialogs[this._chatDialogs.length - 1];
			this.source.close();
			dialog.finished = true;
		},

		//生成图片
		async waitingImage() {
			let content = this.textarea;
			this.textarea = '';
			let saveMsgId = await saveChatDialog({
				role: 'user',
				content,
				lastMsgId: '',
				contentType: 'text',
				aiModelGuid: this.modelPopCofig.guid,
				chatLunciGuid: this._chatingId,
			});

			this._chatDialogs.push({ msgId: 'new', chatRole: 'user', chatContent: content });
			this._chatDialogs.push({
				msgId: 'new',
				chatRole: 'assistant',
				chatContent: '',
				starting: true,
				finished: true,
			});

			// this.msgImgs = [];

			this.toBottom();
			let url = await genImage({
				imgText: content,
				aiModelGuid: this.modelPopCofig.guid,
			});

			if (url) {
				let dialog = this._chatDialogs[this._chatDialogs.length - 1];
				dialog.starting = false;
				dialog.imgUrls = [url];

				dialog.msgId = await saveChatDialog({
					role: 'assistant',
					content: url,
					contentType: 'img',
					aiModelGuid: this.modelPopCofig.guid,
					lastMsgId: this.reqAiConfig.isMultiple ? saveMsgId : '',
					chatLunciGuid: this._chatingId,
				});
			}
		},

		// 生成视频
		async waitingVideo() {
			let content = this.textarea;
			this.textarea = '';
			// let lastMsgId = '';
			// let len = this._chatDialogs.length;
			// if (len > 0) {
			//   lastMsgId = this._chatDialogs[len - 1].msgId;
			// }

			let saveMsgId = await saveChatDialog({
				role: 'user',
				content,
				lastMsgId: '',
				contentType: 'text',
				aiModelGuid: this.modelPopCofig.guid,
				chatLunciGuid: this._chatingId,
			});

			this._chatDialogs.push({ msgId: 'new', chatRole: 'user', chatContent: content });
			this._chatDialogs.push({
				msgId: 'new',
				chatRole: 'assistant',
				chatContent: '',
				starting: true,
				finished: true,
			});

			// this.msgImgs = [];

			this.toBottom();
			let videoRes = await genVideoApi({
				userPrompt: content,
				aiModelGuid: this.modelPopCofig.guid,
			});
			this.$emit('changeOnChatState', false);
			if (videoRes.code == 0) {
				this.queryGenVideo(videoRes.data.orderNo, saveMsgId);
				this.reqAiConfig.isSend = false;
			}
		},
		queryGenVideo(orderNo, saveMsgId) {
			let int = setInterval(async () => {
				let res = await queryGenVideoApi({ orderNo });
				if (res.code == 0) {
					if (res.data.status === 'success') {
						this.reqAiConfig.isSend = true;
						this.$emit('changeOnChatState', true);
						clearInterval(int);
						let dialog = this._chatDialogs[this._chatDialogs.length - 1];
						dialog.starting = false;
						dialog.videoUrls = [res.data.videoUrl];

						dialog.msgId = await saveChatDialog({
							role: 'assistant',
							content: res.data.videoUrl,
							contentType: 'video',
							aiModelGuid: this.modelPopCofig.guid,
							lastMsgId: this.reqAiConfig.isMultiple ? saveMsgId : '',
							chatLunciGuid: this._chatingId,
						});
					}
				} else if (res.data.status === 'fail') {
				}
			}, 5000);
		},
		//获取聊天模型厂商列表
		async getAiVendorList() {
			let res = await getAiVendorListApi();
			this.aiModels = res.data;
			if (res.data.length > 0) {
				this.modelPopCofig.modelVendorSign = res.data[0].sign;
				this.getAiVendorModelList();
			}
		},
		async getAiVendorModelList() {
			this.aiModelChildren = [];
			this.modelPopCofig.isTabLoading = true;
			let res = await getAiVendorModelListApi(this.modelPopCofig);
			this.modelPopCofig.isTabLoading = false;
			this.aiModelChildren = res.data;
			if (res.data.length > 0 && this.modelPopCofig.isFirst) {
				this.reqAiConfig.isSend = true;
				this.modelDetail = res.data[0];
				this.initChatOptions(res.data[0]);
				this.modelPopCofig.guid = res.data[0].guid;
				this.modelPopCofig.modelName = res.data[0].modelName;
				this.modelPopCofig.isFirst = false;
			}
		},
		onModelBtn() {
			this.modelPopCofig.aiPop = !this.modelPopCofig.aiPop;
		},
		onModelChange(item) {
			this.modelPopCofig.modelVendorSign = item.sign;
			this.getAiVendorModelList();
		},
		onModelTypeChange(type) {
			this.modelPopCofig.modelType = type;
			this.getAiVendorModelList();
		},
		onModelChoose(item) {
			this.modelDetail = item;
			this.initChatOptions(item);
			this.modelPopCofig.modelName = item.modelName;
			this.modelPopCofig.guid = item.guid;
			this.modelPopCofig.aiPop = false;
		},
		initChatOptions(item) {
			this.reqAiConfig.maxOutputTokens = Number((item.maxOutputTokens / 2).toFixed());
			this.reqAiConfig.topP = 0.6;
			this.reqAiConfig.temperature = 0.7;
			switch (item.modelType) {
				case 'text':
					this.reqAiConfig.placeholder = '请输入你的问题和需求';
					break;
				case 'image':
					this.reqAiConfig.placeholder = '请描述你想生成的图片(生成图片需等待1-2分钟)';
					break;
				case 'video':
					this.reqAiConfig.placeholder = '请描述你想生成的视频(生成视频需等待较长时间，生成时请耐心等待⌛️)';
					break;
				default:
					break;
			}
		},
	},
	beforeDestroy() {
		document.removeEventListener('click', this.handleClickOutside);
	},
};
</script>

<style scoped lang="scss">
.prompt-btn-box {
	display: flex;
	margin-top: 20px;
	justify-content: end;
}
.xz-user-dropdown-menu {
	width: 300px;
	padding: 10px 20px;
	box-sizing: border-box;
	.logo-box {
		display: flex;
		.logo {
			.img {
				width: 40px;
				height: 40px;
				display: block;
				border-radius: 50%;
			}
		}
		.name-box {
			padding-left: 16px;
			.name {
				color: #6126f5;
				font-size: 16px;
			}
			.label {
				font-size: 14px;
				color: #333;
				margin-top: 3px;
			}
		}
	}
	.count {
		font-size: 16px;
		color: #333333;
		margin-top: 16px;
	}
}
::v-deep .el-dialog__header {
	display: none;
}

::v-deep .el-dialog__body {
	padding: 0px;
	border-radius: 8px;
}

::v-deep .el-dialog {
	border-radius: 8px;
	overflow: hidden;
}
.xz-users-dialog {
	.content {
		display: flex;
		flex-wrap: wrap;
		.item {
			width: 300px;
			height: 180px;
			background: linear-gradient(#ffffff 0%, #ecebf7 100%);
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #ecebf7;
			padding: 15px;
			box-sizing: border-box;
			margin-right: 20px;
			margin-bottom: 20px;
			.top-box {
				display: flex;
				.logo {
					.img {
						width: 73px;
						display: block;
						height: 70px;
					}
				}
				.name-box {
					margin-left: 10px;
					.name {
						font-weight: 600;
						font-size: 20px;
						color: #333333;
					}
					.yq-btn {
						background: linear-gradient(90deg, #9854ec 0%, #4e58ec 53%, #145bec 100%);
						border-radius: 4px;
						color: #ffffff;
						padding: 6px 10px;
						cursor: pointer;
						margin-top: 10px;
						width: fit-content;
					}
				}
			}
			.lable {
				font-size: 13px;
				padding: 10px 0;
				height: 80px;
				color: #666666;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
				overflow: hidden;
				text-overflow: ellipsis;
				box-sizing: border-box;
			}
		}
	}
}
.container {
	display: flex;
	width: 100%;
	height: 100%;

	.switch-box {
		border-top: 1px solid #ebebeb;
		margin-top: 20px;
		padding-top: 20px;

		.item {
			display: flex;
			justify-content: space-between;
			padding-top: 20px;

			.t {
				font-size: 12px;
				color: #333333;
			}
		}
	}

	.slider-box {
		margin-top: 20px;

		.slider {
			position: relative;

			.label {
				position: absolute;
				bottom: 30px;
				right: 0;
				color: #333333;
				font-size: 12px;
			}
		}
	}

	.options-box {
		padding: 20px;
		box-sizing: border-box;
		width: 300px;
		height: 100%;
		border-left: 1px solid #ebebeb;

		.label {
			color: #999999;
			font-size: 11px;
		}

		.title {
			font-size: 12px;
			color: #333333;
		}

		.input-box {
			position: relative;

			.el-dropdown-link {
				position: absolute;
				right: 4px;
				bottom: 4px;
				z-index: 9;
			}

			::v-deep .el-textarea__inner {
				border: 1px solid #6126f5 !important;
			}

			.inp {
				width: 100%;
			}
			.zw-box {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 9;
				width: 100%;
				height: 100%;
				cursor: pointer;
			}
		}
	}
}

.role-drop-box {
	margin-bottom: 10px;

	.role-drop-title {
		width: 120px;
		margin-right: 10px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

::v-deep .el-dropdown-menu__item i {
	margin-right: 0 !important;
}
::v-deep .el-slider__bar {
	height: 10px;
}
::v-deep .el-slider__runway {
	height: 10px;
}
::v-deep .el-slider__button-wrapper {
	top: -14px;
}

.chat-input-box {
	border-radius: 10px;
	border: 1px solid #e1e2e5;
	position: relative;
	display: flex;
	align-items: center;
	padding-right: 10px;

	.input-box {
		::v-deep .el-textarea__inner {
			border: none;
			border-radius: 10px;
			font-size: 12px;
		}

		flex: 1;
	}

	.send-icon {
		cursor: pointer;

		.icon {
			width: 34px;
			height: 34px;
			display: block;
		}
	}

	// .el-dropdown-link {
	// 	position: absolute;
	// 	right: 4px;
	// 	bottom: 4px;
	// 	z-index: 9;
	// }
}

::v-deep .el-dialog__body {
	padding: 10px 20px 20px 20px;
}

.layout-chat-main {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	flex: 1;

	.chat-header {
		border-bottom: 1px solid var(--border-color);
		padding: 14px 20px;
		display: flex;
		align-items: center;
		.left-box {
			display: flex;
			.top-public-btn {
				width: 111px;
				height: 36px;
				border-radius: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				.icon {
					display: block;
					width: 20px;
					height: 20px;
					margin-right: 4px;
				}
			}
			.cooperation-btn {
				border: 1px solid #ff7600;
				color: #ff7600;
			}
			.cooperation-pop-btn {
				border: 1px solid #ff7600;
				color: #ff7600;
				cursor: pointer;
			}
			.invitation-btn {
				border: 1px solid #dee0e4;
				color: #737b8d;
				cursor: pointer;
			}
		}
		.center-box {
			display: flex;
			flex: 1;
			justify-content: end;
			.top-public-btn {
				width: 111px;
				height: 36px;
				border-radius: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				cursor: pointer;
				.icon {
					display: block;
					width: 20px;
					height: 20px;
					margin-right: 4px;
				}
			}
			.end-btn {
				background: linear-gradient(91deg, #fe6947 0%, #fd462e 100%);
				color: #ffffff;
			}
			.back-btn {
				background: linear-gradient(270deg, #145bec 0%, #3670f8 100%);
				color: #ffffff;
				&.active {
					background: #f6f7f9;
					color: #999999;
					cursor: not-allowed;
				}
			}
		}
		.right-box {
			.xz-logo-box {
				margin-left: 20px;
				.img {
					width: 40px;
					height: 40px;
					display: block;
					border-radius: 50%;
				}
			}
		}
	}

	.chat-input {
		border-top: 1px solid var(--border-color);
		padding: 10px 20px;
	}

	.chat-box {
		flex: 1 1;
		overflow: auto;
		padding: 10px 0;
	}

	.send {
		height: 40px;
		width: 80px;
	}

	.popper-box {
		position: relative;

		// .dropdown-warp {
		//   position: absolute;
		//   bottom: 43px;
		//   left: 0px;
		//   z-index: 9;
		//   display: flex;
		// }

		.dropdown-box {
			width: 150px;
			min-height: 200px;
			background: #ffffff;
			box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.08);
			border-radius: 8px;
			border: 1px solid #ebebeb;
			position: absolute;
			bottom: 43px;
			left: 0px;
			z-index: 9;
			box-sizing: border-box;

			.item {
				display: flex;
				justify-content: space-between;
				padding: 10px 15px;
				width: 100%;
				border-radius: 4px;
				color: #333333;
				font-size: 14px;
				box-sizing: border-box;
				align-items: center;
				cursor: pointer;

				&:hover,
				&.active {
					background: #f6f7f9;
				}
			}
		}

		.children-dropdown-box {
			position: absolute;
			bottom: 43px;
			left: 155px;
			width: 600px;
			min-height: 200px;
			background: #ffffff;
			box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.08);
			border-radius: 8px;
			border: 1px solid #ebebeb;
			display: flex;
			padding: 10px;
			box-sizing: border-box;
			z-index: 9;

			.tab {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.item {
					width: 50px;
					height: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #333333;
					font-size: 14px;
					border-radius: 3px;
					margin-bottom: 6px;
					cursor: pointer;

					&:last-child {
						margin-bottom: 0px;
					}

					&:hover,
					&.active {
						background: #f6f7f9;
					}
				}
			}

			.content {
				width: 100%;
				flex: 1;
				padding-left: 10px;
				overflow: hidden;
				box-sizing: border-box;

				.empty {
					height: 100%;
					display: flex;
					align-items: center;
					padding-left: 30px;
					font-size: 12px;
				}

				.item {
					padding: 4px 10px;
					display: flex;
					box-sizing: border-box;
					font-size: 12px;
					// width: 100%;
					overflow: hidden;
					/* 确保超出容器的文本被隐藏 */
					white-space: nowrap;
					/* 防止文本换行 */
					text-overflow: ellipsis;
					cursor: pointer;

					.name {
						width: 100px;
						flex-shrink: 0;
						overflow: hidden;
						/* 确保超出容器的文本被隐藏 */
						white-space: nowrap;
						/* 防止文本换行 */
						text-overflow: ellipsis;
					}

					.price {
						width: 75px;
						flex-shrink: 0;
					}

					.specific {
						flex: 1;
						overflow: hidden;
						/* 确保超出容器的文本被隐藏 */
						white-space: nowrap;
						/* 防止文本换行 */
						text-overflow: ellipsis;
					}

					&:hover,
					&.active {
						background: hsl(220, 20%, 97%);
						color: #6126f5;
					}
				}
			}
		}

		.model-public-btn {
			border-radius: 8px;
			border: 1px solid #dee0e4;
			font-size: 12px;
			color: #737b8d;
			cursor: pointer;
			padding: 6px 10px;
			width: 160px;
			overflow: hidden;
			/* 确保超出容器的文本被隐藏 */
			white-space: nowrap;
			/* 防止文本换行 */
			text-overflow: ellipsis;

			&.active,
			&:hover {
				border-color: #f6f7f9;
				background: #f6f7f9;
			}
		}
	}

	.opt-panel {
		margin-bottom: 10px;
		display: flex;

		// .el-button {
		//   padding: 2px 11px;
		//   font-size: 12px;
		//   font-weight: normal;

		//   i {
		//     font-size: 12px;
		//   }
		// }
	}
}

.role-select-btn {
	border-radius: 8px;
	border: 1px solid #dee0e4;
	font-size: 12px;
	color: #737b8d;
	cursor: pointer;
	padding: 6px 10px;
	width: 160px;
	overflow: hidden;
	/* 确保超出容器的文本被隐藏 */
	white-space: nowrap;
	/* 防止文本换行 */
	text-overflow: ellipsis;

	&:hover {
		border-color: #f6f7f9;
		background: #f6f7f9;
	}
}

.clean-chat-btn {
	border-radius: 8px;
	border: 1px solid #dee0e4;
	font-size: 12px;
	color: #737b8d;
	cursor: pointer;
	padding: 6px 10px;
	overflow: hidden;
	/* 确保超出容器的文本被隐藏 */
	white-space: nowrap;
	/* 防止文本换行 */
	text-overflow: ellipsis;

	&:hover {
		border-color: #f6f7f9;
		background: #f6f7f9;
	}
}
</style>

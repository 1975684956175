<template>
	<el-dialog class="pay-meal-dialog" :before-close="handleClose" :visible="visible" width="900px">
		<div class="dialog-box">
			<div class="title-box">
				<div class="person"><img class="icon" src="../../../static/icon/meal-icon-person.png" /></div>
				<div class="title">购买套餐</div>
				<div class="close" @click="handleClose">
					<img class="icon" src="../../../static/icon/meal-close-icon.png" />
				</div>
			</div>
			<div class="content">
				<div class="item" v-for="(item, idx) of items" :key="idx">
					<div class="title" v-if="idx == 0">
						<img class="icon" src="../../../static/icon/big-vip-header-2.png" />基础版
					</div>
					<div class="title" v-if="idx == 1">
						<img class="icon" src="../../../static/icon/meal-vip-icon2.png.png" />进阶版
					</div>
					<div class="title" v-if="idx == 2">
						<img class="icon" src="../../../static/icon/meal-vip-icon1.png" />专业版
					</div>
					<div class="price"><span class="unit">¥</span>{{ item.price }}</div>
					<div class="label">聊天点数：{{ item.chatCount }}</div>
					<div class="label">有效天数：365天</div>
					<div class="btn" @click="pay(item.guid)">立即购买</div>
					<!-- <el-tag v-if="item.isRecommend == '1'" class="remmd" effect="dark" type="success" size="mini">
            推荐
          </el-tag> -->
				</div>
			</div>
		</div>

		<!-- 
    <div style="padding: 10px">
      <el-row :gutter="20">
        <el-col :span="12" v-for="(item, idx) of items" :key="idx">
          <el-card class="box-card meal-card mt10" shadow="never">
            <div class="center" style="margin-bottom: 10px">
              <el-tag size="mini">
                <label> <i class="el-icon-goods"></i> 价格 ：{{ item.price }} 元</label>
              </el-tag>
            </div>
            <div class="center"><i class="el-icon-check mr4" /> 聊天点数：{{ item.chatCount }}</div>
            <div class="center"><i class="el-icon-check mr4" /> 有效天数 : <label>365 天</label></div>
            <div class="center mt16">
              <el-button type="primary" round size="mini" @click="pay(item.guid)">
                <i class="el-icon-bank-card"></i> 购买
              </el-button>
            </div>
            <el-tag v-if="item.isRecommend == '1'" class="remmd" effect="dark" type="success" size="mini">
              推荐
            </el-tag>
          </el-card>
        </el-col>

        
      </el-row>
    </div> -->
		<div v-html="payForm" ref="payForm"></div>
	</el-dialog>
</template>

<script>
import { buyChatGoods, getChatGoods, bigVipBuyChatGoods } from '@/apis/chat';
// import { getUserBigVipInfoApi } from '@/apis/user';

export default {
	name: 'pay-meal-dialog',

	data() {
		return {
			visible: false,
			items: [],
			payForm: '',
			// isBigVip: false,
		};
	},

	async mounted() {
		this.items = await getChatGoods();
		// this.getUserBigVipInfo();
	},

	methods: {
		async pay(id) {
			let { payForm, orderNo } = await buyChatGoods(id);
			if (payForm) {
				this.payForm = window.atob(payForm);
				this.$nextTick(() => {
					const form = this.$refs.payForm.querySelector('form');
					if (form) {
						form.target = '_blank';
						form.submit();
					}
				});
			}
		},
		async bigVipPay(id) {
			let res = await bigVipBuyChatGoods({
				chatGoodsGuid: id,
				payEnv: 'big_vip_balance',
			});
			if (res.code === 0) {
				this.$message({
					message: '购买成功',
					type: 'success',
				});
				this.$emit('onBuyMeal');
				this.visible = false;
			} else {
				this.$message({
					message: res.msg,
					type: 'info',
				});
			}
		},
		// async getUserBigVipInfo() {
		//   let res = await getUserBigVipInfoApi();
		//   this.isBigVip = res.data.isBigVip;
		// },
		handleClose() {
			this.visible = false;
		},

		show() {
			this.visible = true;
		},

		close() {
			this.visible = false;
		},
	},
};
</script>

<style scoped lang="scss">
.pay-meal-dialog {
	::v-deep .el-dialog {
		border-radius: 12px;
	}

	::v-deep .el-dialog__header {
		display: none;
	}

	::v-deep .el-dialog__body {
		padding: 0;
	}

	.el-card__body {
		div {
			font-size: 12px;
			margin-bottom: 4px;
		}
	}

	.dialog-box {
		width: 100%;
		height: 510px;
		background-image: url('../../../static//icon/meal-bg.png');
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;

		.title-box {
			height: 110px;
			line-height: 110px;
			width: 100%;
			position: relative;

			.title {
				background: linear-gradient(126deg, #d072ff 0%, #06f2ff 50%, #1d75ff 100%);
				-webkit-background-clip: text;
				background-clip: text;
				color: transparent;
				font-size: 44px;
				margin-left: 188px;
				font-weight: 500;
			}

			.person {
				position: absolute;
				left: 34px;
				top: -34px;
				z-index: 9;

				.icon {
					width: 170px;
					height: 170px;
					display: block;
				}
			}

			.close {
				position: absolute;
				right: 40px;
				top: 40px;
				z-index: 9;
				cursor: pointer;

				.icon {
					display: block;
					width: 40px;
					height: 40px;
				}
			}
		}

		.content {
			flex: 1;
			background-color: #fff;
			display: flex;
			justify-content: space-around;
			align-items: center;

			.item {
				width: 240px;
				background: linear-gradient(137deg, #edf2fe 0%, #f4efff 100%);
				border-radius: 16px;
				height: 300px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.btn {
					width: 120px;
					height: 38px;
					background: linear-gradient(90deg, #9854ec 0%, #4e58ec 53%, #145bec 100%);
					border-radius: 23px;
					color: #fff;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 20px;
					cursor: pointer;
				}

				.label {
					color: #2e265d;
				}

				.price {
					color: #d61d25;
					font-size: 22px;
					margin-top: 16px;
					margin-bottom: 10px;

					.unit {
						font-size: 14px;
						margin-right: 8px;
					}
				}

				.title {
					display: flex;
					align-items: center;
					justify-content: center;
					color: #2e265d;

					.icon {
						width: 30px;
						height: 30px;
						display: block;
						margin-right: 6px;
					}
				}
			}
		}
	}

	// .meal-card {
	// 	position: relative;

	// 	label {
	// 		font-weight: 600;
	// 	}
	// }

	// .remmd {
	// 	position: absolute;
	// 	top: 10px;
	// 	right: 10px;
	// }
}
</style>

<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
import Layout from '@/views/app/layout.vue';
import Login from '@/views/account/login.vue';
// import { isMobile, osName } from 'mobile-device-detect';

export default {
	name: 'App',
	components: {
		Login,
		Layout,
	},

	// created() {
	// 	if (osName === 'Android' || osName === 'iOS') {
	// 		let f = confirm('检测到你正在使用手机端访问PC工作台，即将跳转到手机端工作台，是否继续？');
	// 		if (f) {
	// 			window.location.href = 'https://ai.deepcity.cn/';
	// 		}
	// 	}
	// },
};
</script>

<style>
#app {
	width: 100%;
}

#app,
.el-container {
	height: 100%;
}

p {
	margin: 0;
}

html,
body {
	background: white;
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
}

a,
button {
	cursor: pointer;
}

.image-slot {
	background: #f1f5f9;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中 */
}

:root {
	--theme: light;
	--full-height: 100%;

	--black: #303030;
	--gray: rgb(239, 239, 245);
	--primary: #7848f1;
	--primary-light: #ede4fe;
	--hover-color: #f3f3f3;

	--border-color: var(--gray);
	--btn-bg-color: #f1f5f9;

	--text-black: rgb(51, 54, 57);
	--text-black-light: rgb(97, 104, 118);
}

::-webkit-scrollbar {
	width: 4px;
	height: 6px;
}

::-webkit-scrollbar-corner {
	display: block;
}

::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
	border-right-color: transparent;
	border-left-color: transparent;
	background-color: rgba(0, 0, 0, 0.1);
}

:root {
	--font-family-main: 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif !important;
	--font-family-editor: 'CascadiaMono', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
		'Microsoft YaHei', '微软雅黑', Arial, sans-serif !important;
	--border-color: #eee;
}
</style>

<template>
	<el-main class="layout-chat">
		<el-container>
			<layout-cooperation-left
				@changeActiveId="createChat"
				@exitCooperation="helpLunciList"
				:_chatList="chatList"
				:_chatingId="guid"
				:_onChatState="onChatState"
			></layout-cooperation-left>
			<layout-cooperation-main
				:_chatObj="chatObj"
				:_chatingId="guid"
				:_chatDialogs="chatDialogs"
				@clearChatDialogs="clearChatDialogs"
			>
			</layout-cooperation-main>
		</el-container>
	</el-main>
</template>

<script>
import LayoutCooperationLeft from '@/views/app/layout-cooperation/layout-cooperation-left.vue';
import LayoutCooperationMain from '@/views/app/layout-cooperation/layout-cooperation-main.vue';
import { helpLunciList } from '@/apis/user';
import { getChatDialogs } from '@/apis/chat';
export default {
	name: 'layout-tools',
	components: { LayoutCooperationLeft, LayoutCooperationMain },

	async mounted() {},

	data() {
		return {
			chatList: [],
			guid: '',
			chatDialogs: [],
			onChatState: true,
		};
	},

	methods: {
		createChat(id) {
			this.guid = id;
		},
		async helpLunciList() {
			let res = await helpLunciList();
			res.data.forEach(item => {
				if (!(item.lunciInfo == null)) {
					this.chatList.push(item);
				}
			});
			// this.chatList = res.data;
			let xzGuid = this.$route.query.xzGuid;
			if (xzGuid) {
				let index = res.data.findIndex(item => {
					return item.guid === xzGuid;
				});
				if (index === -1) {
					this.guid = res.data[0].chatLunciGuid;
				} else {
					this.guid = res.data[index].chatLunciGuid;
				}
				return;
			}
			if (res.data.length > 0) {
				this.guid = res.data[0].chatLunciGuid;
			}
		},
		clearChatDialogs() {
			this.chatDialogs = [];
		},
		changeOnChatState(state) {
			this.onChatState = state;
		},
	},
	mounted() {
		this.helpLunciList();
	},
	computed: {
		chatObj() {
			let obj = this.chatList.find(item => item.chatLunciGuid == this.guid);
			return obj || null;
		},
	},
	watch: {
		async guid(id) {
			this.chatDialogs = [];
			let chats = await getChatDialogs({ chatLunciGuid: id });
			this.$nextTick(() => {
				chats.forEach(item => {
					item.finished = true;
					if (item.chatRole === 'assistant') {
						if (item.contentType == 'img') {
							item.imgUrls = [item.chatContent];
							item.chatContent = '';
						}
						if (item.contentType == 'video') {
							item.videoUrls = [item.chatContent];
							item.chatContent = '';
						}
					}
				});
				this.chatDialogs = chats;
			});
		},
	},
};
</script>

<style lang="scss">
.layout-draw {
	//border-right: solid 1px var(--border-color);
	padding: 0 !important;
}
</style>

<template>
    <el-dialog title="专业参数说明" class="pro-examples-info" :before-close="handleClose" :visible="visible" width="40%">
        <el-input type="textarea" :rows="20" v-model="info"></el-input>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="close">OK</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {

    name: 'pro-examples-info',
    data() {
        return {
            visible: false,
            info: '专业参数请选择原画风格，避免影响参数渲染效果。\n' +
                '\n' +
                '参数说明\n' +
                '\n' +
                '常用参数：\n' +
                '\n' +
                '--ar n:m 图片尺寸宽:高（Aspect Ratios），例如：--ar 16:9\n' +
                '\n' +
                '--chaos 0-100 变异程度，默认 0。数字越大，图片生成的想象力越大，例如：--chaos 50\n' +
                '\n' +
                '--iw 0-2 参考图权重，值越大，参考图的权重越大，默认 1。例如：--iw 1.25（仅在v5或者niji5模型下有效）\n' +
                '\n' +
                '--no 元素 排除某些元素，例如：--no plants，生成图中将不包含plants\n' +
                '\n' +
                '--q <.25、.5、1> 指定生成图的质量，默认 1。例如：--q .5（仅在v4、5，niji5下有效）\n' +
                '\n' +
                '--style raw 减少 midjourney 的艺术加工，生成更摄影化的图片。例如：--style raw（只在v5.1下有效）\n' +
                '\n' +
                '--style <cute, expressive, original, or scenic> 设置动漫风格：可爱、表现力、原始、或者风景。例如：--style cute（只在--niji 5下有效）\n' +
                '\n' +
                '--s（或--stylize） 数字 设置midjourney的艺术加工权重，默认 100。例如：--s 100。取值范围： 0-1000（v4、v5），626-60000（v3），niji模式下无效\n' +
                '\n' +
                '--niji 模型设置。设置为日本动漫风格模型，例如：--niji，也可以写成：--niji 5（目前 5 可以省略）\n' +
                '\n' +
                '--v <1-5>\t模型设置。设置模型版本，例如：--v 5\n'
        }
    },
    methods: {

        handleClose(){
            this.visible = false
        },

        show() {
            this.visible = true
        },

        close() {
            this.visible = false
        },
    }
};
</script>


<style lang="scss">

.pro-examples-info {

    .el-dialog__body{
        padding:10px;
    }

}

</style>

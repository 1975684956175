import Cookies from 'js-cookie'

const TokenKey = 'token'
const SessionKey = 'session'

export function getToken() {

    // let session = getSession()
    // return session ? session.token : ''

    return Cookies.get('token') || ''
}

export function setToken(token) {
    Cookies.set('token', token)
}

// export function setToken(token) {
//     return Cookies.set(TokenKey, token)
// }

export function setUserName(username) {
    Cookies.set('username', username)
}

export function getUserName() {
    return Cookies.get('username')
}

export function removeToken() {
    return Cookies.remove('token')
}

export function getSession() {
    let str = Cookies.get(SessionKey)
    if (str) {
        return JSON.parse(str)
    } else {
        return undefined
    }
}

export function setSession(session) {
    return Cookies.set(SessionKey, JSON.stringify(session))
}

export function removeSession() {
    return Cookies.remove(SessionKey)
}

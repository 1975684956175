<template>
	<el-main class="layout-gallery">
		<el-container>
			<!--            <layout-draw-left></layout-draw-left>-->
			<layout-gallery-main></layout-gallery-main>
		</el-container>
	</el-main>
</template>

<script>
import LayoutDrawLeft from '@/views/app/layout-draw/layout-draw-left.vue';
import LayoutGalleryMain from '@/views/app/layout-gallery/layout-gallery-main.vue';

export default {
	name: 'layout-gallery',
	components: { LayoutGalleryMain, LayoutDrawLeft },

	data() {
		return {};
	},
	computed: {},
	methods: {},
};
</script>

<style lang="scss">
.layout-gallery {
	padding: 0 !important;
	width: 100%;
	height: 100%;
}
</style>

<template>
	<el-main class="layout-create-center">
		<div class="container">
			<div class="logo-box">
				<img class="logo" :src="userInfo.avatarUrl" /><span class="name">{{ userInfo.nickname }}欢迎回来~</span>
			</div>
			<div class="content-box">
				<div class="card-box">
					<div class="item" :style="{ backgroundImage: 'url(' + bg1 + ')', color: '#1B0C6E' }">
						<div class="title">累计调试次数</div>
						<div class="content">
							<span class="number">{{ lunciData.totalChatCount }}</span> <span class="unit">次</span>
						</div>
					</div>
					<div class="item" :style="{ backgroundImage: 'url(' + bg2 + ')', color: '#0C296E' }">
						<div class="title">协作中聊天轮次</div>
						<div class="content">
							<span class="number">{{ lunciData.doingHelpCount }}</span> <span class="unit">次</span>
						</div>
					</div>
					<div class="item" :style="{ backgroundImage: 'url(' + bg3 + ')', color: '#004761' }">
						<div class="title">协作完成聊天轮次</div>
						<div class="content">
							<span class="number">{{ lunciData.finishHelpCount }}</span> <span class="unit">次</span>
						</div>
					</div>
					<div class="item" :style="{ backgroundImage: 'url(' + bg4 + ')', color: '#613000' }">
						<div class="title">累计协作收益</div>
						<div class="content">
							<span class="number">{{ lunciData.totalRewardPoints }}</span> <span class="unit">点</span>
						</div>
					</div>
				</div>
				<div class="tab-box">
					<div class="tab">
						<!-- <div class="item" :class="{ active: tabActive === 'wait' }" @click="onTab('wait')">待协作</div> -->
						<div class="item" :class="{ active: tabActive === 'doing' }" @click="onTab('doing')">
							协作中
						</div>
						<div class="item" :class="{ active: tabActive === 'finish' }" @click="onTab('finish')">
							协作完成
						</div>
					</div>
					<div class="content">
						<el-table :data="chatList" empty-text="暂无轮次信息">
							<el-table-column property="lunciInfo.chatLunciTitle" label="协作轮次名称">
								<template slot-scope="scope">
									<div v-if="scope.row.lunciInfo === null" class="nameT">对方聊天轮次已删除</div>
									<div v-else>{{ scope.row.lunciInfo.chatLunciTitle }}</div>
								</template>
							</el-table-column>
							<el-table-column property="lunciUser.nickname" label="请求协作用户"></el-table-column>
							<el-table-column property="helpStatus" label="协作状态">
								<template slot-scope="scope">
									<div v-if="scope.row.helpStatus == 0" class="stateT">协作中</div>
									<!-- <div v-if="scope.row.helpStatus == 0" class="stateT">待协作</div> -->
									<div v-if="scope.row.helpStatus == 1" class="stateT">协作完成</div>
								</template>
							</el-table-column>
							<el-table-column property="helpRewardPoints" label="当前轮次可获得点数"></el-table-column>
							<el-table-column property="debugChatCount" label="累计调试点数"></el-table-column>
							<el-table-column fixed="right" label="操作">
								<template slot-scope="scope">
									<div
										class="btn"
										@click="onGotoCooperation(scope.row.guid)"
										size="small"
										v-if="scope.row.helpStatus == 0"
									>
										前往协作
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
		</div>
	</el-main>
</template>
<script>
import bg1 from '@/static/imgs/center-bg1.png';
import bg2 from '../../../static/imgs/center-bg2.png';
import bg3 from '../../../static/imgs/center-bg3.png';
import bg4 from '../../../static/imgs/center-bg4.png';
import { helpUserOverviewApi, helpUserInfoApi, helpLunciList } from '@/apis/user';
export default {
	data() {
		return {
			bg1: bg1,
			bg2: bg2,
			bg3: bg3,
			bg4: bg4,
			lunciData: {
				totalChatCount: 0,
				waitHelpCount: 0,
				finishHelpCount: 0,
				totalRewardPoints: 0,
				doingHelpCount: 0,
			},
			userInfo: {},
			chatList: [],
			chatDoing: [],
			chatWait: [],
			chatFinish: [],
			tabActive: 'doing',
		};
	},
	methods: {
		onGotoCooperation(guid) {
			this.$router.push('/app/cooperation?xzGuid=' + guid);
		},
		async helpUserOverview() {
			let res = await helpUserOverviewApi();
			this.lunciData = { ...this.lunciData, ...res.data };
		},
		async helpUserInfo() {
			let res = await helpUserInfoApi();
			this.userInfo = res.data.info;
		},
		async helpLunciList() {
			let res = await helpLunciList();
			// this.chatList = res.data;
			res.data.forEach(element => {
				if (element.helpStatus === 0) {
					this.chatDoing.push(element);
				} else if (element.helpStatus === 1) {
					this.chatFinish.push(element);
				}

				// else if (element.helpStatus === 2) {
				// 	this.chatFinish.push(element);
				// }
			});
			this.chatList = this.chatDoing;
		},
		onTab(type) {
			this.tabActive = type;
			// if (type === 'wait') {
			// 	this.chatList = this.chatWait;
			// }
			if (type === 'doing') {
				this.chatList = this.chatDoing;
			}
			if (type === 'finish') {
				this.chatList = this.chatFinish;
			}
		},
	},
	mounted() {
		this.helpUserInfo();
		this.helpUserOverview();
		this.helpLunciList();
	},
};
</script>
<style scoped lang="scss">
.layout-create-center {
	padding: 0px;
	background-color: #f9f8ff;
	position: relative;
}

.container {
	width: 1200px;
	margin: 45px auto 0 auto;

	.content-box {
		background-color: #ffffff;
		border-radius: 8px;
		margin-top: 20px;
		padding: 30px;
	}

	.tab-box {
		margin-top: 40px;

		.tab {
			display: flex;

			.item {
				color: #666666;
				font-size: 14px;
				position: relative;
				padding: 8px 10px;
				margin-right: 16px;
				cursor: pointer;

				&.active {
					&::after {
						content: '';
						width: 100%;
						position: absolute;
						bottom: 0;
						left: 0;
						height: 3px;
						background: linear-gradient(131deg, #9854ec 0%, #4e58ec 53%, #145bec 100%);
						border-radius: 3px;
					}
				}
			}
		}

		.content {
			margin-top: 20px;

			.stateT {
				color: #ff7715;
			}
			.nameT {
				color: #c52929;
			}

			.btn {
				width: 82px;
				height: 28px;
				background: rgba(62, 38, 245, 0.05);
				border-radius: 4px 4px 4px 4px;
				border: 1px solid #7c71f8;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #3626f5;
				font-size: 12px;
				cursor: pointer;
			}
		}
	}

	.card-box {
		display: flex;
		justify-content: space-around;

		.item {
			width: 270px;
			height: 144px;
			padding-top: 30px;
			padding-left: 40px;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			box-sizing: border-box;

			.title {
				font-size: 18px;
				margin-bottom: 10px;
			}

			.number {
				font-size: 35px;
			}

			.unit {
				font-size: 20px;
			}
		}
	}

	.logo-box {
		display: flex;
		align-items: center;

		.logo {
			width: 48px;
			height: 48px;
			display: block;
		}

		.name {
			margin-left: 10px;
			color: #6126f5;
		}
	}
}
</style>

<template>
	<!-- width="390px" -->
	<!-- <el-aside class="layout-chat-left"> -->
	<div class="layout-chat-left">
		<div class="search-panel">
			<!-- <el-button @click="createChat" type="primary" size="small"><i class="el-icon-plus"></i></el-button> -->
			<el-input
				v-model="searchWord"
				@input="filter"
				size="large"
				placeholder="搜索"
				prefix-icon="el-icon-search"
			/>
		</div>

		<div class="chats-panel pt10">
			<div
				v-show="item.visible"
				:class="_chatingId == item.guid ? 'active' : ''"
				class="chat-item"
				v-for="(item, idx) of _chatList"
				:key="idx"
			>
				<div class="chat-name-box">
					<div class="name">
						<span @click="chooseChat(item)">{{ item.chatLunciTitle }}</span>
						<a class="ml10" @click="modifyDialog"><i class="el-icon-edit"></i></a>
					</div>
					<div class="date" @click="chooseChat(item)">{{ item.showTime }}</div>
				</div>
				<div class="chat-icon-box">
					<div class="opts">
						<el-popconfirm @confirm="removeChatDialog(item.guid)" title="确定删除吗 ？">
							<a slot="reference"><i class="el-icon-delete"></i></a>
						</el-popconfirm>
					</div>
				</div>
			</div>
		</div>
		<div class="btn-box">
			<div @click="createChat" class="add-chat-btn">
				<img class="icon" src="@/static/icon/chat-add-icon.png" /> 新增对话
			</div>
		</div>
	</div>
	<!-- </el-aside> -->
</template>

<script>
import { removeChatDialog, updateChat } from '@/apis/chat';

export default {
	name: 'layout-chat-left',

	async mounted() {},

	props: {
		_chatingId: String,
		_chatList: Array,
		_onChatState: Boolean,
	},

	data() {
		return {
			searchWord: '',
		};
	},

	methods: {
		filter() {
			if (this.searchWord === '') {
				this._chatList.forEach(item => {
					item.visible = true;
				});
			} else {
				this._chatList.forEach(item => {
					item.visible = item.chatLunciTitle.includes(this.searchWord);
				});
			}
		},

		async removeChatDialog(guid) {
			await removeChatDialog(guid);
			this.$emit('reloadList');
			this.$message({ message: '已删除', type: 'success' });
		},

		modifyDialog() {
			let self = this;

			let chatObj = this._chatList.find(item => item.guid == this._chatingId);
			let idx = this._chatList.findIndex(item => item.guid == self._chatingId);

			this.$prompt('', '修改对话', {
				inputValue: chatObj.chatLunciTitle,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(async ({ value }) => {
				await updateChat(chatObj.guid, value);
				this.$set(this._chatList[idx], 'chatLunciTitle', value);

				self.$message({
					showClose: true,
					message: '更新成功',
					type: 'success',
				});
			});
		},

		createChat() {
			this.$emit('createChat');
		},

		chooseChat({ guid }) {
			if (this._onChatState) {
				this.$emit('update:_chatingId', guid);
			} else {
				this.$message({
					showClose: true,
					message: '有生成任务进行中，暂不可切换',
					type: 'warning',
				});
				return;
			}
		},
	},

	computed: {},
};
</script>

<style lang="scss" scoped>
.layout-chat-left {
	width: 250px;
	flex-shrink: 0;
	border-right: solid 1px var(--border-color);
	padding: 10px;
	background: rgb(250 251 255);
	background-color: #fff;
	overflow-y: auto;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	position: relative;

	.chats-panel {
		flex: 1;
		padding-bottom: 68px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.add-chat-btn {
		width: 225px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: linear-gradient(90deg, #f5eefe 0%, #edeefd 53%, #e8effe 100%);
		color: #4958ec;
		font-size: 14px;
		border-radius: 8px;
		position: absolute;
		left: 10px;
		bottom: 10px;
		cursor: pointer;

		.icon {
			display: block;
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}

	.chat-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		// border: solid 1px var(--border-color);
		// background: white;
		background-color: #fafbfc;
		border-radius: 6px;
		margin-bottom: 8px;

		.chat-name-box {
			flex: 1;
			cursor: pointer;
		}

		.opts {
			color: var(--text-black-light);
		}

		.name {
			font-size: 15px;
			font-weight: bold;
			margin-bottom: 4px;
			color: var(--text-black);
		}

		.date {
			font-size: 12px;
			color: var(--text-black-light);
		}

		&.active,
		&:hover {
			//border: solid 1px var(--primary);
			// background: var(--primary-light);

			.name {
				// color: var(--primary);
				color: #4958ec;
			}

			.opts {
				color: #4958ec;
			}
		}
	}
}

@media screen and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
	.layout-chat-left {
		width: 200px;

		.chat-item {
			padding: 4px;
			margin-bottom: 40px;
			position: relative;

			// flex-direction: column;
			// align-items: start;
			.name {
				font-size: 12px;
			}

			.chat-icon-box {
				position: absolute;
				bottom: -28px;
				left: 0px;
				width: 100%;
			}

			.opts {
				width: 100%;
				display: flex;
				justify-content: space-around;
			}
		}
	}
}
</style>

import { getSession, getToken } from '@/utils/auth'

const Hashes = require('jshashes')
const MD5 = new Hashes.MD5;
const Hash = new Hashes.SHA1;

/*
export const autographFun = (configData) => {

    let config = configData

    console.log(config)

    config.method = config.method.toUpperCase()

    let signData;
    let queryData = {
        'app_guid': config.urlSuffix.app_guid,
        'app_type': config.urlSuffix.app_type,
        token: config.urlSuffix.token
    }
    let queryMd5 = MD5.hex(JSON.stringify(queryData))
    if (config.method === 'POST') {
        let dataMd5 = config.data ? MD5.hex(JSON.stringify(config.data)) : ''
        signData = config.method + '\n' + dataMd5 + '\n' + queryMd5 + '\n' + (config.data ? 'application/json' : 'text/plain') + '\n' + config.urlSuffix.expires + '\n' + config.urlSuffix.noncestr + '\n' + '/' + config.url.toLowerCase()
    } else {
        signData = config.method + '\n' + queryMd5 + '\n' + config.urlSuffix.expires + '\n' + config.urlSuffix.noncestr + '\n' + '/' + config.url.toLowerCase()
    }

    console.log('config.urlSuffix.token :' + config.urlSuffix.token, '\nsignData :' + signData)

    return Hash.b64_hmac(config.urlSuffix.token, signData)
}*/


export const autographFun = (configData) => {

    let config = configData;
    let signData;
    let queryData = {
        app_guid: config.urlSuffix.app_guid,
        app_type: config.urlSuffix.app_type,
        token: config.urlSuffix.token
    }

    let queryMd5 = MD5.hex(JSON.stringify(queryData))

    if (config.method === 'POST' || config.method == 'post') {
        let dataMd5 = config.data ? MD5.hex(JSON.stringify(config.data)) : ''
        signData = 'POST' + '\n' + dataMd5 + '\n' + queryMd5 + '\n' + 'application/json' + '\n' + config.urlSuffix.expires + '\n' + config.urlSuffix.noncestr + '\n' + '/' + (config.url.toLowerCase())

        // console.log(signData, '')

    } else {
        signData = config.method + '\n' + queryMd5 + '\n' + config.urlSuffix.expires + '\n' + config.urlSuffix.noncestr + '\n' + '/' + (config.url.toLowerCase())
    }


    return Hash.b64_hmac(config.urlSuffix.token, signData)
}

import { service as request } from '@/libs/request'
// 工具屋分类列表
export const getClassListApi = (data) => request.post('square/api.ContentGeneration/copywritingCategory', data);

// 分类内容列表
export const getClassContentListApi = (data) => request.post('square/api.ContentGeneration/categoryContentList', data);

// 分类内容详情
export const getClassDetailApi = (data) => request.post('square/api.ContentGeneration/catgoryContentInfo', data);

// 保存文本内容
export const saveToolMsgContentApi = (data) => request.post('user/api.userWork/saveTextContent', data);

// 收藏文案分类
export const collectClassApi = (data) => request.post('square/api.ContentGeneration/userCollectionCate', data);

// 取消收藏文案分类
export const cancelClassApi = (data) => request.post('square/api.ContentGeneration/userCancelCate', data);

// 用户收藏的场景分类列表
export const collectClassListApi = (data) => request.post('square/api.ContentGeneration/userCollectionCateList', data);

// 新增创作背景
// export const workBgCreateApi = (data) => request.post('user/api.userWork/workBgCreate', { data });

// 修改创作背景
// export const workBgEditApi = (data) => request.post('user/api.userWork/workBgEdit', { data });

// 创作背景列表删除
// export const workBgDelApi = (data) => request.post('user/api.userWork/workBgDel', { data });

// 创作背景列表
export const workBgListApi = (data) => request.post('user/api.userWork/workBgList');
// 保存聊天信息
export const saveMsgApi = (data) => request.post('square/api.chat/saveMsgV2', data);

// 获取Ai消息
export const getAiMsgApi = (data) => request.get('square/api.chat/sendAll', { params: data });

<template>
	<el-main class="layout-draw">
		<el-container>
			<layout-tools-left @changeActiveClassId="createChat"></layout-tools-left>
			<layout-tools-main :toolId="toolId"></layout-tools-main>
		</el-container>
	</el-main>
</template>

<script>
import LayoutToolsLeft from '@/views/app/layout-tools/layout-tools-left.vue';
import LayoutToolsMain from '@/views/app/layout-tools/layout-tools-main.vue';

export default {
	name: 'layout-tools',
	components: { LayoutToolsLeft, LayoutToolsMain },

	async mounted() {},

	data() {
		return {
			genImages: [],
			status: '0', // 0 - 未处理，1 - 处理中，2 - 已经完成
			config: {},
			toolId: 0,
		};
	},

	methods: {
		createChat(id) {
			this.toolId = id;
		},
	},
};
</script>

<style lang="scss">
.layout-draw {
	//border-right: solid 1px var(--border-color);
	padding: 0 !important;
}
</style>

import Vuex from 'vuex'

export default new Vuex.Store({
    state: {
        // 在这里定义你的状态
        chatItems: [],
        chatIdSelected: null
    },
    mutations: {
        // 在这里定义如何修改你的状态
    },
    actions: {
        // 在这里定义异步操作
    },
    getters: {
        chatItemSelected: state => {
            return state.chatItems.find(item => item.id === state.chatIdSelected);
        }
    }
})

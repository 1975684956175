<script>
import {
	getPuserVipInfoApi,
	getUserBigVipInfoApi,
	applyBigVipApi,
	bigVipApplyListApi,
	getAccountListApi,
	withdrawAccountAddApi,
	withdrawAccountDeleteApi,
	withdrawApplyApi,
	searchUserApi,
	giveAiPointApi,
	sendAiPointRecordApi,
	withdrawApplyListApi,
	bigVipInviteListApi,
	getBigVipConfigApi,
	exchangeVipPointApi,
	helpUserInfoApi,
} from '@/apis/user';
import PayMealDialog from '@/views/app/dialogs/pay-meal-dialog.vue';

export default {
	components: { PayMealDialog },
	data() {
		return {
			vipInfo: {
				cardName: '',
				cardDesc: '',
				cardEndTime: '',
				cardNotice: '',
			},
			bigVipInfo: {
				isBigVip: false,
				isAbleApply: false,
				bigVipInfo: {
					balance: '',
					aiPoint: '',
					balanceTotal: '',
				},
			},
			applyFormVisible: false,
			applyListVisible: false,
			txApplyListVisible: false,
			invitationListVisible: false,
			txApplyList: [],
			invitationList: [],
			invitationTotal: 0,
			inviteReq: {
				pageSize: 10,
				page: 1,
			},
			applyForm: {
				applyRemark: '',
			},
			applyList: [],
			addCashOutVisible: false,
			cashOutListVisible: false,
			cashOutVisible: false,
			showGiveVisible: false,
			giveHistoryVisible: false,
			showBuyVisible: false,
			addCashOutForm: {
				withdrawMethod: '3',
				withdrawAccount: '',
				withdrawName: '',
				bankName: '',
				bankBranchName: '',
			},
			cashOutForm: {
				withdraw_method_guid: '',
				withdraw_amount: 1,
				withdraw_remark: '',
			},
			givePrintForm: {
				keywords: '',
				giveUserGuid: '',
				aiPoint: 1,
			},
			bugPrintFrom: {
				exchangeAmount: 1,
			},
			giveUsers: [],
			giveHistory: [],
			accountList: [],
			invitationPath: '',
			cashOutStep: 0,
			buyPointStep: 0,
			buyBigVipRuleText: '',
			xzInfo: {
				is_join: false,
			},
		};
	},
	mounted() {
		this.getUserVipInfo();
		this.getUserBigVipInfo();
		this.getBigVipConfig();
		this.onQueryIsCreate();
	},
	methods: {
		getMerchantInfo(merchantInfo, key, defaultValue = '') {
			const value = merchantInfo.find(item => {
				return item.key === key;
			});
			return value ? value.value : defaultValue;
		},
		async getBigVipConfig() {
			let res = await getBigVipConfigApi();
			this.cashOutStep = parseInt(this.getMerchantInfo(res.data, 'big_vip_withdraw_amount_limit', 1));
			this.buyPointStep = parseInt(this.getMerchantInfo(res.data, 'big_vip_one_yuan_exchange_ai_point', 1));
			this.buyBigVipRuleText = this.getMerchantInfo(res.data, 'big_vip_apply_notice_text', '');
		},
		async getUserVipInfo() {
			let res = await getPuserVipInfoApi();
			this.vipInfo.cardDesc = res.data.cardInfo.cardDesc;
			this.vipInfo.cardName = res.data.cardInfo.cardName;
			this.vipInfo.cardEndTime = res.data.vipInfo.cardEndTime;
			this.vipInfo.cardNotice = res.data.cardInfo.cardNotice;
		},
		async getUserBigVipInfo() {
			let res = await getUserBigVipInfoApi();
			if (res.code === 0) {
				this.bigVipInfo = res.data;
				let origin = window.location.origin;
				this.invitationPath = `${origin}/?inviteCode=${res.data.bigVipInfo.inviteCode}&type=bigVip`;
			} else {
				this.invitationPath = '邀请链接生成失败';
			}
		},
		onShowApplyVip() {
			this.applyFormVisible = true;
		},
		async onShowApplyRecord() {
			let res = await bigVipApplyListApi();
			this.applyList = res.data;
			this.applyListVisible = true;
		},
		//提现弹框
		async ShowTXDialog() {
			let res = await withdrawApplyListApi();
			this.txApplyList = res.data;
			this.txApplyListVisible = true;
		},
		//邀请记录弹框
		async onShowIntDialog() {
			this.bigVipInviteList();
			this.invitationListVisible = true;
		},
		async bigVipInviteList() {
			let res = await bigVipInviteListApi(this.inviteReq);
			this.invitationList = res.data.data;
		},
		async handlePageChang(page) {
			this.inviteReq.page = page;
			bigVipInviteList();
		},
		async onApply() {
			let res = await applyBigVipApi({ applyRemark: this.applyForm.applyRemark });
			if (res.code === 0) {
				this.$message({
					message: '申请成功',
					type: 'success',
				});
				this.applyFormVisible = false;
			} else {
				this.$message({
					message: res.msg,
					type: 'info',
				});
			}
		},
		onCashOut() {
			// this.cashOutVisible = true;
			this.cashOutListVisible = true;
			this.getAccountList();
		},
		handleClick() {},
		async getAccountList() {
			let res = await getAccountListApi();
			this.accountList = res.data;
		},
		async onSubmitPayMode() {
			if (this.addCashOutForm.withdrawMethod == 1 || this.addCashOutForm.withdrawMethod == 2) {
				if (
					this.addCashOutForm.withdrawAccount.trim().length > 0 &&
					this.addCashOutForm.withdrawName.trim().length > 0
				) {
					await withdrawAccountAddApi(this.addCashOutForm);
					this.getAccountList();
					this.addCashOutVisible = false;
					this.cashOutListVisible = true;
					this.$message({
						message: '新增成功',
						type: 'success',
					});
				} else {
					this.$message({
						message: '请完善账号信息',
						type: 'info',
					});
				}
			} else if (this.addCashOutForm.withdrawMethod == 3) {
				if (
					this.addCashOutForm.withdrawAccount.trim().length > 0 &&
					this.addCashOutForm.withdrawName.trim().length > 0 &&
					this.addCashOutForm.bankName.trim().length > 0 &&
					this.addCashOutForm.bankBranchName.trim().length > 0
				) {
					// this.clearYH();
					await withdrawAccountAddApi(this.addCashOutForm);
					this.getAccountList();
					this.addCashOutVisible = false;
					this.cashOutListVisible = true;
					this.$message({
						message: '新增成功',
						type: 'success',
					});
				} else {
					this.$message({
						message: '请完善账号信息',
						type: 'info',
					});
				}
			}
		},
		clearYH() {
			this.addCashOutForm.bankName = '';
			this.addCashOutForm.bankBranchName = '';
		},
		async onWithdrawal(guid) {
			this.cashOutForm.withdraw_method_guid = guid;
			this.cashOutVisible = true;
		},
		async onDeleteMode(item) {
			await withdrawAccountDeleteApi({ guid: item.guid });
			this.$message({
				message: '删除成功',
				type: 'success',
			});
			this.getAccountList();
		},
		onShowAddDialog() {
			this.cashOutListVisible = false;
			this.addCashOutVisible = true;
		},
		showPayMeal() {
			// this.$refs.paymeal.show();
			this.showBuyVisible = true;
		},
		async onBuySubmit() {
			let res = await exchangeVipPointApi(this.bugPrintFrom);
			if (res.code === 0) {
				this.$message({
					message: '购买成功',
					type: 'success',
				});
				this.showBuyVisible = false;
				this.getUserBigVipInfo();
			} else {
				this.$message({
					message: res.msg,
					type: 'info',
				});
			}
		},
		// onPricechange(res) {
		// 	this.cashOutForm.withdraw_amount = res.toFixed(0);
		// },
		onPrintchange(res) {
			this.givePrintForm.aiPoint = res.toFixed(0);
		},
		onBuyPrintchange(res) {
			this.bugPrintFrom.exchangeAmount = res.toFixed(0);
		},
		//发起提现申请
		async onAddCashSubmit() {
			try {
				let res = await withdrawApplyApi(this.cashOutForm);
				if (res.code === 0) {
					this.$message({
						message: '提现申请成功',
						type: 'success',
					});
					this.cashOutVisible = false;
				} else {
					this.$message({
						message: res.msg,
						type: 'info',
					});
				}
			} catch (error) {}
		},
		onShowGivePrint() {
			this.showGiveVisible = true;
		},
		async onSeachUser() {
			if (!this.givePrintForm.keywords) {
				return;
			}
			let res = await searchUserApi({
				keywords: this.givePrintForm.keywords,
			});
			this.giveUsers = res.data;
		},
		async onGiveSubmit() {
			if (!this.givePrintForm.giveUserGuid) {
				return;
			}
			let res = await giveAiPointApi(this.givePrintForm);
			if (res.code === 0) {
				this.$message({
					message: '赠送成功',
					type: 'success',
				});
				this.showGiveVisible = false;
			} else {
				this.$message({
					message: res.msg,
					type: 'info',
				});
			}
		},
		async onGiveHistory() {
			this.giveHistoryVisible = true;
			let res = await sendAiPointRecordApi();
			this.giveHistory = res.data;
		},
		onCopyIntPath() {
			this.$copyText(this.invitationPath);
			this.$message({ message: '已复制邀请链接', type: 'success' });
		},
		async onQueryIsCreate() {
			let res = await helpUserInfoApi();
			this.xzInfo.is_join = res.data.is_join;
			// if (res.data.is_join) {
			//   this.$router.push('/app/xzcenter');
			// } else {
			//   this.$router.push('/app/xzcreate');
			// }
		},
		onGoToCreate() {
			this.$router.push('/app/xzcreate');
		},
		onGoToCenter() {
			this.$router.push('/app/xzcenter');
		},
	},
};
</script>

<template>
	<el-main class="layout-big-vip">
		<div class="header-box">
			<img class="icon" src="../../../static/imgs/big-vip-hedaer-bg.png" />
		</div>
		<div class="container">
			<div class="now-vip">
				<div class="vip-info">
					<p class="card-name">{{ vipInfo.cardName }}</p>
					<div class="vip-notice" v-html="vipInfo.cardDesc"></div>
					<div class="btn">权益生效中</div>
				</div>
				<div class="config-box">
					<div class="item">
						<div class="lable">账号权益享有：</div>
						<div class="content">个人</div>
					</div>
					<div class="item">
						<div class="lable">权益到期时间：</div>
						<div class="content">{{ vipInfo.cardEndTime }}</div>
					</div>
					<div class="item">
						<div class="lable">权益郑重说明：</div>
						<div class="content" v-html="vipInfo.cardNotice"></div>
					</div>
				</div>
			</div>
			<div class="vip-config" v-if="bigVipInfo.isBigVip">
				<p class="title">我的大会员：</p>
				<div class="vip-content">
					<div class="item">
						<div class="lable">可提现金额：</div>
						<div class="btn-box">
							<div class="price">￥{{ bigVipInfo.bigVipInfo.balance }}</div>
							<div class="sub-btn">
								<div class="button b1" @click="onCashOut">提现</div>
								<div class="button b2" @click="ShowTXDialog">提现记录</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="lable">助理算力：</div>
						<div class="btn-box">
							<div class="price">{{ bigVipInfo.bigVipInfo.aiPoint }}</div>
							<div class="sub-btn">
								<div class="button b1" @click="showPayMeal">采购算力</div>
								<div class="button b2" @click="onShowGivePrint">赠送算力</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="lable">累积分佣金额：￥{{ bigVipInfo.bigVipInfo.balanceTotal }}</div>
						<div class="btn-box">
							<div class="price">{{ bigVipInfo.bigVipInfo.balanceTotal }}</div>
							<div class="sub-btn"></div>
						</div>
					</div>
					<div class="item">
						<div class="lable">分佣邀请链接：</div>
						<div class="btn-box">
							<div class="price">
								<span class="invitationText">{{ invitationPath }}</span>
							</div>
							<div class="sub-btn">
								<div class="button b1" @click="onCopyIntPath">复制邀请链接</div>
								<div class="button b2" @click="onShowIntDialog">邀请记录</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="lable">协作者联盟</div>
						<div class="btn-box">
							<div class="sub-btn">
								<div class="button c2" @click="onGoToCenter" v-if="xzInfo.is_join">我的协作</div>
								<div class="button c1" @click="onGoToCreate" v-else>加入协作者联盟</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="vip-desc" v-if="!bigVipInfo.isBigVip">
				<p class="title">大会员权益：</p>
				<!-- <p class="li">
					大会员开通规则：年费用户可申请成为大会员，审批通过后即可成为大会员。大会员拥有以下权益：
				</p>
				<p class="li">1、大会员享受更低的AI点数购买资格，AI点数赠送资格</p>
				<p class="li">2、邀请用户购买，会员返佣30%的权益，返佣金额可提现或购买AI点数</p>
				<p class="li">3、进入合作者专属社区</p> -->
				<div class="info" v-html="buyBigVipRuleText"></div>
				<!-- v-if="bigVipInfo.isAbleApply" -->
				<div class="btn-box">
					<!-- <div class="button" >申请开通大会员</div> -->
					<div>
						<el-button type="primary" :disabled="!bigVipInfo.isAbleApply" @click="onShowApplyVip"
							>申请开通大会员</el-button
						>
						<el-button type="info" :disabled="!bigVipInfo.isAbleApply" @click="onShowApplyRecord"
							>申请记录</el-button
						>
					</div>
					<p class="tip" v-if="!bigVipInfo.isAbleApply">开通年费会员后可申请</p>
					<!-- <div class="button-record" :disabled="bigVipInfo.isAbleApply" >申请记录</div> -->
				</div>
			</div>
		</div>

		<el-dialog title="申请大会员" :visible.sync="applyFormVisible">
			<div class="public-dialog">
				<div class="header">申请大会员</div>
				<div class="content">
					<el-form :model="applyForm">
						<el-form-item label="申请备注" label-width="80px">
							<el-input v-model="applyForm.applyRemark" autocomplete="off"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="btn-box">
					<div class="btn two" @click="onApply">申 请</div>
					<div class="btn two b2" @click="applyFormVisible = false">取消</div>
				</div>
				<!-- <div slot="footer" class="dialog-footer">
          <el-button @click="applyFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="onApply">申 请</el-button>
        </div> -->
			</div>
		</el-dialog>
		<el-dialog title="申请记录" :visible.sync="applyListVisible">
			<div class="public-dialog">
				<div class="header">申请记录</div>
				<div class="content">
					<el-table :data="applyList">
						<el-table-column
							property="applyRemark"
							:show-overflow-tooltip="true"
							label="申请备注"
							width="200"
						></el-table-column>
						<el-table-column property="refuseReason" label="拒绝理由" width="200"></el-table-column>
						<el-table-column property="applyStatusText" label="申请状态"></el-table-column>
					</el-table>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="提现申请记录" :visible.sync="txApplyListVisible" width="600px">
			<div class="public-dialog">
				<div class="header">提现申请记录</div>
				<div class="content">
					<el-table :data="txApplyList">
						<!-- <el-table-column property="withdrawOrderNo" label="单号" width="200"></el-table-column> -->
						<el-table-column property="withdrawAmount" label="提现金额"></el-table-column>
						<el-table-column property="withdrawStatusText" label="提现状态" width="100"></el-table-column>
						<el-table-column property="payVoucherImg" label="提现凭证" width="120">
							<template slot-scope="scope">
								<el-image
									style="width: 80px; height: 80px"
									:src="scope.row.payVoucherImg"
									:preview-src-list="[scope.row.payVoucherImg]"
								>
								</el-image>
							</template>
						</el-table-column>
						<el-table-column property="withdrawTime" label="提现时间"></el-table-column>
					</el-table>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="邀请记录" :visible.sync="invitationListVisible" width="600px">
			<div class="public-dialog">
				<div class="header">邀请记录</div>
				<div class="content">
					<el-table :data="invitationList">
						<el-table-column property="inviteUser.headImgurl" label="头像" width="80">
							<template slot-scope="scope">
								<el-image
									style="width: 30px; height: 30px"
									:src="scope.row.inviteUser.headImgurl"
									:preview-src-list="[scope.row.inviteUser.headImgurl]"
								>
								</el-image>
							</template>
						</el-table-column>
						<el-table-column property="inviteUser.nickname" label="用户名"></el-table-column>
						<!-- <el-table-column property="inviteRechargeAmount" label="充值金额"></el-table-column> -->
						<el-table-column property="createTime" label="邀请时间"></el-table-column>
					</el-table>
					<el-pagination
						small
						background
						layout="prev, pager, next"
						:total="invitationTotal"
						:current-page="inviteReq.page"
						@current-change="handlePageChang"
						class="mt20"
					>
					</el-pagination>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="新增提现账号" :visible.sync="addCashOutVisible" width="600px">
			<div class="public-dialog">
				<div class="header">新增提现账号</div>
				<div class="content">
					<el-tabs v-model="addCashOutForm.withdrawMethod">
						<!-- <el-tab-pane label="微信" name="1">
					<el-input
						v-model="addCashOutForm.withdrawAccount"
						size="medium"
						placeholder="请输入微信账号"
					></el-input>
					<div class="mt10">
						<el-input
							size="medium"
							v-model="addCashOutForm.withdrawName"
							placeholder="请输入收款人姓名"
						></el-input>
					</div>
				</el-tab-pane>
				<el-tab-pane label="支付宝" name="2">
					<el-input
						size="medium"
						v-model="addCashOutForm.withdrawAccount"
						placeholder="请输入支付宝账号"
					></el-input>
					<div class="mt10">
						<el-input
							size="medium"
							v-model="addCashOutForm.withdrawName"
							placeholder="请输入收款人姓名"
						></el-input>
					</div>
				</el-tab-pane> -->
						<el-tab-pane label="银行卡" name="3">
							<el-input
								size="medium"
								v-model="addCashOutForm.withdrawAccount"
								placeholder="请输入银行卡账号"
							></el-input>
							<div class="mt10">
								<el-input
									size="medium"
									v-model="addCashOutForm.withdrawName"
									placeholder="请输入收款人姓名"
								></el-input>
							</div>
							<div class="mt10">
								<el-input
									size="medium"
									v-model="addCashOutForm.bankName"
									placeholder="收款银行"
								></el-input>
							</div>
							<div class="mt10">
								<el-input
									size="medium"
									v-model="addCashOutForm.bankBranchName"
									placeholder="请输入收款银行支行"
								></el-input>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div class="btn-box">
					<div class="btn one" @click="onSubmitPayMode">提 交</div>
				</div>
				<!-- <div class="mt10">
        <el-button type="primary" @click="onSubmitPayMode">提 交</el-button>
      </div> -->
			</div>
		</el-dialog>
		<el-dialog title="提现列表" :visible.sync="cashOutListVisible" width="700px">
			<div class="public-dialog">
				<div class="header">提现列表</div>
				<!-- <el-button @click="onShowAddDialog" type="primary" size="small"> 新增提现账号 </el-button> -->
				<div class="content">
					<el-table :data="accountList" empty-text="请新增提现账号">
						<el-table-column property="withdrawMethod" label="提现方式" width="100">
							<template slot-scope="scope">
								<div v-if="scope.row.withdrawMethod == 1">微信提现</div>
								<div v-if="scope.row.withdrawMethod == 2">支付宝提现</div>
								<div v-if="scope.row.withdrawMethod == 3">银行卡提现</div>
							</template>
						</el-table-column>
						<el-table-column property="withdrawAccount" label="账号" width="150"></el-table-column>
						<el-table-column property="withdrawName" label="收款人" width="150"></el-table-column>
						<el-table-column fixed="right" label="操作">
							<template slot-scope="scope">
								<!-- <el-popconfirm
							title="确认删除该提现方式吗？"
							icon="el-icon-info"
							icon-color="red"
							@confirm="onDeleteMode(scope.row)"
						> -->
								<el-button type="text" size="small" @click="onDeleteMode(scope.row)"> 删 除 </el-button>
								<!-- </el-popconfirm> -->
								<el-button @click="onWithdrawal(scope.row.guid)" type="text" size="small"
									>使用该账号提现</el-button
								>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="btn-box">
					<div class="btn one" @click="onShowAddDialog">新增提现账号</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="发起提现申请" :visible.sync="cashOutVisible" width="600px">
			<div class="public-dialog">
				<div class="header">发起提现申请</div>
				<div class="content">
					<el-form ref="form" :model="cashOutForm" label-width="80px">
						<!-- @change="onPricechange" -->
						<el-form-item label="提现金额">
							<el-input-number
								v-model="cashOutForm.withdraw_amount"
								controls-position="right"
								:step="cashOutStep"
								step-strictly
								:min="cashOutStep"
							></el-input-number>
							<span>
								(请输入{{ cashOutStep }}的倍数)可提现金额：￥{{ bigVipInfo.bigVipInfo.balance }}</span
							>
						</el-form-item>
						<el-form-item label="备注">
							<el-input
								size="medium"
								v-model="cashOutForm.withdraw_remark"
								placeholder="请输入备注（选填）"
							></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="btn-box">
					<div class="btn one" @click="onAddCashSubmit">提交</div>
				</div>
			</div>
		</el-dialog>

		<el-dialog title="赠送算力" :visible.sync="showGiveVisible" width="600px">
			<div class="public-dialog">
				<div class="header">发起提现申请</div>
				<div class="content">
					<el-form ref="form" :model="givePrintForm" label-width="80px">
						<el-form-item label="用户名">
							<el-input size="medium" v-model="givePrintForm.keywords" placeholder="请输入用户名">
								<el-button slot="append" icon="el-icon-search" @click="onSeachUser"></el-button
							></el-input>
						</el-form-item>
						<el-form-item label="赠送算力">
							<el-input-number
								v-model="givePrintForm.aiPoint"
								controls-position="right"
								@change="onPrintchange"
								:min="1"
								:max="bigVipInfo.bigVipInfo.aiPoint"
							></el-input-number>
						</el-form-item>
						<el-form-item label="选择用户">
							<div v-if="giveUsers.length > 0">
								<el-radio
									v-model="givePrintForm.giveUserGuid"
									:label="item.guid"
									v-for="item in giveUsers"
									:key="item.guid"
									>{{ item.nickname }}</el-radio
								>
							</div>
							<p v-else>请搜索想要赠送的用户</p>
						</el-form-item>
					</el-form>
				</div>
				<div class="btn-box">
					<div class="btn two" @click="onGiveSubmit">赠 送</div>
					<div class="btn two b2" @click="onGiveHistory">记 录</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="购买算力" :visible.sync="showBuyVisible" width="600px">
			<div class="public-dialog">
				<div class="header">购买算力</div>
				<div class="content">
					<el-form ref="form" :model="givePrintForm" label-width="80px">
						<el-form-item label="购买算力">
							<el-input-number
								v-model="bugPrintFrom.exchangeAmount"
								controls-position="right"
								@change="onBuyPrintchange"
								:min="1"
								:max="parseFloat(bigVipInfo.bigVipInfo.balance)"
							></el-input-number>
							元 = {{ bugPrintFrom.exchangeAmount * buyPointStep }}点算力
						</el-form-item>
						<el-form-item label="会员价:"> 当前大会员1元可兑换{{ buyPointStep }}点算力 </el-form-item>
					</el-form>
				</div>
				<div class="btn-box">
					<div class="btn one" @click="onBuySubmit">购 买</div>
				</div>
				<!-- <div class="mt10">
        <el-button type="primary" @click="onBuySubmit">购 买</el-button>
      </div> -->
			</div>
		</el-dialog>
		<el-dialog title="赠送记录" :visible.sync="giveHistoryVisible" width="600px">
			<div class="public-dialog">
				<div class="header">赠送记录</div>
				<div class="content">
					<el-table :data="giveHistory" empty-text="暂无赠送记录">
						<el-table-column property="acceptUser.nickname" label="用户名"></el-table-column>
						<el-table-column property="sendAiPoint" label="赠送算力" width="100"></el-table-column>
						<el-table-column property="sendTime" label="赠送时间"></el-table-column>
					</el-table>
				</div>
			</div>
		</el-dialog>
		<pay-meal-dialog ref="paymeal"></pay-meal-dialog>
		<div class="person">
			<img class="img" src="../../../static/imgs/big-vip-person.png" />
		</div>
	</el-main>
</template>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
	display: none;
}

::v-deep .el-dialog__body {
	padding: 0px;
	border-radius: 8px;
}

::v-deep .el-dialog {
	border-radius: 8px;
	overflow: hidden;
}

.public-dialog {
	background-image: url('../../../static/imgs/big-vip-bg.png');
	background-size: 100% 298px;
	background-repeat: no-repeat;
	width: 100%;
	min-height: 500px;
	background-color: #fff;
	padding: 0 30px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	color: #333333;

	.header {
		height: 80px;
		font-size: 22px;
		color: #ffffff;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.content {
		flex: 1;
		background-color: #fff;
		padding: 10px;
	}

	.btn-box {
		display: flex;
		justify-content: center;
		padding: 20px 0;

		.btn {
			border-radius: 8px 8px 8px 8px;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			&.one {
				background: linear-gradient(90deg, #9854ec 0%, #4e58ec 53%, #145bec 100%);
				width: 400px;
				height: 50px;
			}

			&.two {
				background: linear-gradient(90deg, #9854ec 0%, #4e58ec 53%, #145bec 100%);
				width: 200px;
				height: 50px;
				margin-left: 20px;

				&.b2 {
					background: #dee0e4;
					color: #333333;
				}
			}
		}
	}
}

.person {
	position: absolute;
	left: 0px;
	bottom: 0px;

	.img {
		display: block;
		width: 300px;
		height: 300px;
	}
}

.container {
	width: 1130px;
	margin: 45px auto 0 auto;
}

.layout-big-vip {
	padding: 0px;
	background: linear-gradient(134deg, #f8faff 0%, #f9f7ff 100%);
	position: relative;

	.header-box {
		.icon {
			display: block;
			width: 100%;
			height: 108px;
		}
	}
}

::v-deep .el-table thead {
	color: #383838;
}

.vip-config {
	margin-top: 30px;

	.vip-content {
		background-color: #fff;
		padding: 20px;
	}

	.title {
		margin-bottom: 16px;
		color: #333333;
		font-weight: bold;
	}

	.item {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 10px;
		border-bottom: 1px solid #ebebeb;
		padding: 10px 0;

		.lable {
			font-size: 14px;
			color: #333333;
		}

		.btn-box {
			display: flex;
			align-items: center;

			.price {
				margin-right: 20px;
				color: #6126f5;
			}
		}

		.sub-btn {
			display: flex;
			align-items: center;
			width: 260px;
		}

		.button {
			width: fit-content;
			padding: 0 10px;
			width: 100px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			font-size: 14px;

			margin-right: 10px;
			border-radius: 4px;
			cursor: pointer;

			&.b1 {
				background-color: #6126f5;
				color: #fff;
			}

			&.b2 {
				background-color: #dee0e4;
				color: #333333;
			}

			&.c1 {
				background: linear-gradient(130deg, #9854ec 0%, #4e58ec 53%, #145bec 100%);
				color: #fff;
			}

			&.c2 {
				background-color: #f2eeff;
				border: 1px solid #6126f5;
				color: #6126f5;
			}
		}

		.invitationText {
			font-weight: normal;
			text-decoration: underline;
		}
	}
}

.now-vip {
	display: flex;

	.vip-info {
		width: 340px;
		height: 185px;
		background-image: url('@/static/icon/vip-bg.png');
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 30px 20px;
		box-sizing: border-box;
		position: relative;
		flex-shrink: 0;

		.card-name {
			font-size: 24px;
			color: #47426f;
		}

		.vip-notice {
			box-sizing: border-box;
			font-size: 12px;
			width: 170px;
			margin-top: 16px;
		}

		.btn {
			width: 108px;
			height: 34px;
			background: linear-gradient(268deg, #7d45f4 0%, #5c9bff 100%);
			box-shadow: 0px 2px 0px 1px rgba(255, 255, 255, 0.26);
			border-radius: 35px 35px 35px 35px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			position: absolute;
			z-index: 9;
			right: 30px;
			bottom: 20px;
		}
	}

	.config-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 30px;

		.item {
			display: flex;
			margin-top: 15px;

			.lable {
				color: #8d8e99;
				font-size: 16px;
			}

			.content {
				color: #333333;
				font-size: 16px;
				flex: 1;
			}
		}

		// .card-date {
		//   text-align: right;
		//   color: #2e4bbf;
		// }
	}
}

.vip-desc {
	margin-top: 30px;
	background-color: #fff;
	padding: 20px;

	.title {
		font-weight: bold;
		margin-bottom: 20px;
	}

	.li {
		margin-top: 4px;
		color: #000000;
		font-size: 14px;
	}

	.btn-box {
		display: flex;
		margin-top: 20px;
		align-items: end;
		flex-direction: column;

		.tip {
			font-size: 14px;
			margin-top: 8px;
			color: #ccc;
		}

		.button {
			width: fit-content;
			padding: 0 10px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			font-size: 14px;
			color: #fff;
			margin-right: 6px;
			background: linear-gradient(
					230.47deg,
					rgb(255, 164, 28) -0.87%,
					rgb(254, 65, 65) 25.89%,
					rgb(244, 21, 62) 77.87%,
					rgb(255, 39, 195) 97.94%
				),
				rgb(231, 56, 40);
			border-radius: 4px;
			cursor: pointer;
		}

		.button-record {
			width: fit-content;
			padding: 0 10px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			font-size: 14px;
			color: #fff;
			margin-right: 6px;
			background: #6c6b6a;
			border-radius: 4px;
			cursor: pointer;
		}
	}
}
</style>

import { service as request } from '@/libs/request'
import { Message } from 'element-ui'

export function createChat (params = {}) {
  return request.post('square/api.chat/createLunci', params)
}

export function getChat (params) {
  return request.post('square/api.chat/nowLunci', params)
}

export async function getNowChat (params = {}) {
  let { data } = await request.post('square/api.chat/nowLunci', params)
  return data
}

export async function getChatList (params) {
  let { data } = await request.post('square/api.chat/lunciList', params)
  return data
}

export async function getChatDialogs ({ startId = 0, page = 1, chatLunciGuid, is_all = 1 }) {
  let { data } = await request.post('square/api.chat/history', { startId, page, chatLunciGuid, is_all })
  return data.reverse()
}

export function removeChat ({ chatLunciGuid }) {
  return request.post('square/api.chat/lunciDelete', { chatLunciGuid })
}

export async function updateChat (chatLunciGuid, chatLunciTitle) {
  return await request.post('square/api.chat/updateLunci', { chatLunciGuid, chatLunciTitle })
}

// 发送聊天 ...
export async function saveChatDialog (req) {
  let { data } = await request.post('square/api.chat/saveMsgV2', req)
  return data.msgId
}

export async function chatgpt (msgId) {
  const result = await fetch("https://ai-api.deepcity.cn/square/api.chat/sendOpen?msgId=" + msgId)
  return result
}

export async function removeChatDialog (chatLunciGuid) {
  return await request.post('/square/api.chat/lunciDelete', { chatLunciGuid })
}

export async function getAbleModels (req) {
  let { data } = await request.post('/square/api.chat/ableModel', req)
  return data
}

export async function getRobotRoles () {
  let { data } = await request.post('/square/api.chat/chatRobotList')
  return data
}

export async function clearLunci (chatLunciGuid) {
  await request.post('square/api.chat/clearLunci', { chatLunciGuid })
}

// 生成图片
export async function genImage (req) {
  let { code, data, msg } = await request.post('/square/api.chat/chatBuildImg', req)
  if (code != 0) {
    Message({ message: msg, type: 'error' })
    return null
  } else {
    return data.url
  }
}

// 生成视频
export async function genVideoApi (req) {
  let res = await request.post('/user/api.userWork/videoCreate', req)
  return res
}
// 查询视频生成结果
export async function queryGenVideoApi (req) {
  let res = await request.post('/user/api.userWork/videoResultQuery', req)
  return res
}

// 获取聊天商品
export async function getChatGoods () {
  let { data } = await request.post('/square/api.chatGoods/index')
  return data
}

// 购买聊天商品
export async function buyChatGoods (chatGoodsGuid) {
  let { data } = await request.post('/square/api.chatGoods/buy', { chatGoodsGuid, payEnv: 'alipay_pc' })
  return data
}

export function bigVipBuyChatGoods (data) {
  return request.post('/square/api.chatGoods/buy', data)
}

// 商家PC端外链列表 /merchant/apiindex/pcToolsUrls
export async function getMerchantPcToolsUrls (req) {
  let { data } = await request.post('/merchant/api.index/pcToolsUrls', req)
  return data
}
// 商家PC端外链分类列表
export async function pcToolsUrlCategoryApi (data) {
  let res = await request.post('/merchant/api.index/pcToolsUrlCategory', data)
  return res
}

// 聊天收藏
export async function collectionChatSave (msgId) {
  let data = await request.post('/square/api.chat/collectionChatSave', msgId)
  return data
}

// 帮助轮次角色列表
export async function helpRoleList (chatLunciGuid) {
  let data = await request.post('/square/api.chat/helpRoleList', chatLunciGuid)
  return data
}

// 新增帮助轮次角色
export async function addHelpRole (data) {
  let res = await request.post('/square/api.chat/addHelpRole', data)
  return res
}

// 修改帮助轮次角色
export async function editHelpRole (data) {
  let res = await request.post('/square/api.chat/editHelpRole', data)
  return res
}

// 删除帮助轮次角色
export async function deleteHelpRole (data) {
  let res = await request.post('/square/api.chat/deleteHelpRole', data)
  return res
}

// 退出轮次协作
export async function exitHelpLunci (data) {
  let res = await request.post('/square/api.chat/exitHelpLunci', data)
  return res
}

// 聊天模型厂商列表
export async function getAiVendorListApi () {
  let res = await request.post('/square/api.chat/aiVendorList')
  return res
}

// 聊天模型厂商模型列表
export async function getAiVendorModelListApi (data) {
  let res = await request.post('/square/api.chat/aiVendorModelList', data)
  return res
}

// 聊天轮次协助详情
export async function chatLunciHelpInfoApi (data) {
  let res = await request.post('/square/api.chat/chatLunciHelpInfo', data)
  return res
}

// 协作者联盟用户列表
export async function lunciHelpUserListApi (data) {
  let res = await request.post('/square/api.chat/lunciHelpUserList', data)
  return res
}

// 选择协作者联盟用户协作
export async function lunciSelectHelpUserApi (data) {
  let res = await request.post('/square/api.chat/lunciSelectHelpUser', data)
  return res
}

// 轮次拥有人更新聊天轮次协作状态
export async function lunciOwnerUpdateStatusApi (data) {
  let res = await request.post('/square/api.chat/lunciOwnerUpdateStatus', data)
  return res
}

// 协作者更新聊天轮次协作状态
export async function lunciHelperUpdateStatusApi (data) {
  let res = await request.post('/square/api.chat/lunciHelperUpdateStatus', data)
  return res
}

// 保存聊天轮次提示词
export async function lunciPromptSaveApi (data) {
  let res = await request.post('/square/api.chat/lunciPromptSave', data)
  return res
}

// 历史帮助角色列表
export const historyHelpRolesApi = (data) => request.post('/square/api.chat/historyHelpRoles', data);
<template>
	<div class="chat-box">
		<div
			class="chat-item"
			:key="idx"
			v-for="(item, idx) of _chatDialogs"
			:class="item.chatRole != 'assistant' ? 'mine' : ''"
		>
			<div class="header-img ml10" v-if="item.chatRole != 'assistant'">
				<img v-if="session" :src="session.headImgUrl" alt="" />
			</div>

			<div class="header-img ml10" v-if="item.chatRole == 'assistant'">
				<img
					src="https://xiaoyi-1317629730.cos.ap-guangzhou.myqcloud.com/image/76ab72d19e2249a28f77e2d488de8c3f.jpg"
					alt=""
				/>
			</div>

			<div class="content">
				<div v-show="item.starting" class="loading">思考中 ...</div>
				<!-- <div class="chat-content md-htm" v-html="renderHtml(item.chatContent)"></div> -->
				<v-md-preview v-show="!item.starting" class="preview-box" :text="item.chatContent"></v-md-preview>
				<el-image
					v-for="(img, idx) of item.imgUrls"
					:src="img"
					:preview-src-list="item.imgUrls"
					:key="idx"
				></el-image>
				<video width="220" height="160" controls v-if="item.videoUrls && item.videoUrls.length > 0">
					<source :src="item.videoUrls[0]" type="video/mp4" />
					<source :src="item.videoUrls[0]" type="video/ogg" />
					您的浏览器不支持 HTML5 video 标签。
				</video>
				<div
					class="pause-box"
					v-if="item.chatRole == 'assistant'"
					@click="onPause"
					v-show="!item.starting && !item.finished"
				>
					<i class="el-icon-video-pause"></i>暂停推理
				</div>
			</div>
			<div class="copy">
				<a class="" @click="copy(item.chatContent)"> <i class="el-icon-document-copy"></i> </a>
				<a class="star" v-if="item.chatRole != 'assistant'" @click="collection(item)">
					<i class="el-icon-star-off"></i>
				</a>
				<!-- && item.imgUrls || item.imgUrls.length > 0 || item.videoUrls || item.videoUrls.length > 0 -->
				<div
					class="download"
					v-if="item.chatRole == 'assistant' && item.imgUrls && item.imgUrls.length > 0"
					@click="onDown(item, 'img')"
				>
					<i class="el-icon-download"></i>
				</div>
				<div
					class="download"
					v-if="item.chatRole == 'assistant' && item.videoUrls && item.videoUrls.length > 0"
					@click="onDown(item, 'video')"
				>
					<i class="el-icon-download"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import { md } from '@/libs/markdown';
import 'highlight.js/scss/default.scss';
import 'highlight.js/styles/atom-one-dark.css';
import { getSession } from '@/utils/auth';
import { collectionChatSave } from '@/apis/chat';
export default {
	name: 'chat-box',

	props: {
		_chatDialogs: Array,
	},

	created() {
		this.session = getSession();
	},

	data() {
		return {
			session: null,
		};
	},
	// watch: {
	// 	_chatDialogs(newChat) {
	// 		this.chats = [];
	// 		this.$nextTick(() => {
	// 			this.chats = newChat;
	// 		});
	// 	},
	// },

	methods: {
		// renderHtml(str) {
		// 	return md.render(str);
		// },

		copy(str) {
			this.$copyText(str);
			this.$message({ message: '已复制', type: 'success' });
		},
		async collection(item) {
			let res = await collectionChatSave({ msgId: item.msgId });
			if (res.code === 0) {
				this.$message({ message: '收藏成功', type: 'success' });
			} else {
				this.$message({ message: '收藏失败', type: 'error' });
			}
		},
		generateRandomFileName(url) {
			const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
			const length = 10; // 设置文件名长度

			let randomFileName = '';
			for (let i = 0; i < length; i++) {
				const randomIndex = Math.floor(Math.random() * characters.length);
				randomFileName += characters.charAt(randomIndex);
			}
			let lastDotIndex = url.lastIndexOf('.');
			let hz = '';
			if (lastDotIndex === -1) {
				hz = ''; // 如果没有找到后缀名，返回空字符串
			} else {
				hz = url.substring(lastDotIndex);
			}
			return randomFileName + hz;
		},
		onDown(item, type) {
			let name = '';
			let url = '';
			if (type == 'img') {
				url = item.imgUrls[0]; // 替换为你的文件 URL
				name = this.generateRandomFileName(item.imgUrls[0]);
			}
			if (type == 'video') {
				url = item.videoUrls[0]; // 替换为你的文件 URL
				name = this.generateRandomFileName(item.videoUrls[0]);
			}
			let link = document.createElement('a');
			link.href = url;
			link.download = name; // 替换为你的文件名
			link.target = '_blank';
			link.rel = 'noopener noreferrer';
			link.click();
		},
		onPause() {
			this.$emit('onPause');
		},
	},
};
</script>

<style>
.preview-box .github-markdown-body {
	padding: 0px;
	background-color: inherit;
	font-size: inherit;
	color: inherit;
	letter-spacing: 1px;
}

.preview-box .github-markdown-body p {
	margin-bottom: 0px;
}

:-webkit-any(article, aside, nav, section) h1 {
	font-size: 24px;
}

:-webkit-any(article, aside, nav, section) :-webkit-any(article, aside, nav, section) h1 {
	font-size: 24px;
}
</style>

<style lang="scss" scoped>
.chat-box {
	.el-image {
		background: white;
		max-width: 200px;
		max-height: 200px;
		margin: 4px;
	}

	.chat-item.mine {
		flex-direction: row-reverse;

		.content {
			background-color: #f6f7f9;
			// background-color: var(--primary);
			// color: white;
			color: #120649;
			padding: 10px 12px;
		}
	}

	.hljs {
		padding: 10px;
		border-radius: 6px;
	}

	.chat-item {
		display: flex;
		padding: 20px;
		box-sizing: border-box;

		.header-img {
			img {
				width: 40px;
				height: 40px;
				border-radius: 100%;
			}
		}

		.copy {
			font-size: 12px;
			display: flex;
			align-items: center;
			// justify-content: center;
			flex-direction: column;

			.star {
				margin-top: 10px;
				font-size: 14px;
				text-align: center;
			}

			.download {
				cursor: pointer;
				margin-top: 10px;
				font-size: 14px;
				text-align: center;
			}
		}

		.content {
			// background-color: #f1f5f9;
			border-radius: 6px;
			max-width: 750px;
			min-width: 120px;
			padding: 0px 12px;
			margin-left: 10px;
			font-size: 13px;
			word-wrap: break-word;
			overflow-wrap: break-word;
			white-space: normal;
			text-overflow: ellipsis;
			position: relative;

			.pause-box {
				display: flex;
				align-items: center;
				cursor: pointer;
				color: #4958ec;
				font-size: 14px;
				position: absolute;
				bottom: -26px;
				left: 10px;

				.el-icon-video-pause {
					font-size: 18px;
					margin-right: 6px;
				}
			}
		}
	}

	.md-htm {
		padding: 4px;

		h1 {
			font-size: 18px !important;
		}

		& > pre {
			padding: 0;
			margin-top: 6px;
			margin-bottom: 6px;
			background: transparent !important;
		}

		table {
			width: 100%;
			border-collapse: collapse;
			//color: #6e6b7b;
			margin: 10px;
			background: white;
		}

		table,
		th,
		td {
			border: 1px solid #ddd9e9;
		}

		th,
		td {
			padding: 4px 15px;
			text-align: left;
		}

		th {
			//background-color: #EFEFF4;
		}
	}
}

@media screen and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
	.chat-box {
		.chat-item {
			padding: 10px;

			.content {
				max-width: 75%;
			}
		}
	}
}
</style>

<template>
	<el-main class="layout-draw">
		<el-container>
			<layout-draw-left
				@showImages="setImages"
				:_status="status"
				:_newOrderId="newOrderId"
				:_tabActive="contentIndex"
				@changeStatus="changeStatus"
				@updateConfig="updateConfig"
				@changeContent="changeContent"
			></layout-draw-left>
			<layout-draw-main
				:_images="genImages"
				:_status="status"
				v-show="contentIndex === 'create'"
			></layout-draw-main>
			<layout-gallery-main v-if="contentIndex === 'history'" @createImg="createNewImg"></layout-gallery-main>
		</el-container>
	</el-main>
</template>

<script>
import LayoutDrawLeft from '@/views/app/layout-draw/layout-draw-left.vue';
import LayoutDrawMain from '@/views/app/layout-draw/layout-draw-main.vue';
import LayoutGalleryMain from '@/views/app/layout-gallery/layout-gallery-main.vue';
export default {
	name: 'layout-draw',
	components: { LayoutDrawMain, LayoutDrawLeft, LayoutGalleryMain },

	async mounted() {},

	data() {
		return {
			genImages: [
				'https://xiaoyi-1317629730.cos.ap-guangzhou.myqcloud.com/image/55d3039bb20b4830bc11a5a1e8701cce.png',
			],
			status: '0', // 0 - 未处理，1 - 处理中，2 - 已经完成
			config: {},
			contentIndex: 'create', // create history
			newOrderId: '',
		};
	},

	methods: {
		setImages(imgs) {
			this.genImages = imgs;
		},

		changeStatus(status) {
			this.status = status;
		},

		updateConfig(config) {
			this.config = config;
		},
		//修改内容显示
		changeContent(index) {
			this.contentIndex = index;
		},
		//放大或变换图片
		createNewImg(orderId) {
			this.newOrderId = orderId;
			this.contentIndex = 'create';
		},
	},
};
</script>

<style lang="scss">
.layout-draw {
	//border-right: solid 1px var(--border-color);
	padding: 0 !important;
}
</style>

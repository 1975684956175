<template>
  <el-main class="layout-create-center">
    <div class="header-box">
      <img class="icon" src="../../../static/imgs/create-header.png">
    </div>
    <div class="container">
      <div class="label-box">
        欢迎加入协作者联盟！我们是一个致力于通过协作、共享AI能力来实现创造力与收益的联盟。我们为您提供了一个平台，让您展示您的独特能力并与其他协作者合作，为用户提供出色的AI对话场景体验。</br>
        作为联盟成员，您将有机会获得用户选择您作为协作对象的机会。当用户需要协作时，他们将能看到您的个人资料和技能。您可以帮助用户完成AI对话的提示词编写，提供其他AI能力辅助，以满足用户的需求。</br>
        在每次成功协作结束后，我们将奖励您协作过程中产生算力点数的50%作为回报，作为对您付出和贡献的认可。</br>
        我们鼓励协作者们在协作者联盟平台上展现创造力，与其他成员协作，并取得共同成功。通过共享您的AI能力和经验，您不仅可以为用户提供优质的服务，还能在联盟中建立更多有意义的联系和合作机会。</br>
        请注意，我们鼓励和推崇合作、创新、共享的价值观。我们禁止任何形式的侵权、盗用他人作品和不合法行为。请确保您的作品和行为符合相关法律法规和道德准则。我们将重视知识产权保护，保护协作者的权益。</br>
        协作者联盟是一个充满活力和创造力的社区，我们相信您的加入将为联盟带来新的活力和价值。让我们共同努力，通过协作，为用户创造出精彩纷呈的AI对话场景体验！
      </div>
      <div class="form-box">
        <div class="title-box">请填写信息，加入联盟</div>
        <div class="content">
          <div class="logo">
            <el-upload class="avatar-uploader" action="https://ai-api.deepcity.cn/user/api.userinfo/uploadImg"
              :show-file-list="false" name="img" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <div class="relative">
                <el-image class="header-img" title="点击上传头像" :src="applyForm.avatarUrl"></el-image>
                <div class="uploading flex-center" v-show="uploading">
                  <i class="spin el-icon-loading"></i>
                </div>
                <div class="btn-edit" title="点击上传头像">
                  <img src="../../../static/icon/create-edit-icon.png" />
                </div>
              </div>
            </el-upload>
          </div>
          <div class="form">
            <el-form :model="applyForm">
              <el-form-item label="昵称" label-width="80px">
                <el-input class="inp" v-model="applyForm.nickname" placeholder="请输入名称（必填）"
                  autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="优势介绍" label-width="80px">
                <el-input class="inp" v-model="applyForm.introduction" type="textarea" row="2" resize="none"
                  placeholder="请输入优势介绍" autocomplete="off"></el-input>
              </el-form-item>

            </el-form>
            <div class="btn-box">
              <div class="btn" @click="onJoin">立即加入</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-main>
</template>
<script>
import {
  joinHelpUsersApi
} from '@/apis/user';
export default {
  data() {
    return {
      applyForm: {
        avatarUrl: 'https://xiaoyi-1317629730.cos.ap-guangzhou.myqcloud.com/image/d1511a2c95cb4f108f39499c5475cbf1.png',
        nickname: '',
        introduction: '',
      },
      uploading: false,
    }
  },
  methods: {
    handleAvatarSuccess({ code, data }) {
      if (code == 0) {
        this.applyForm.avatarUrl = data;
      }
      this.uploading = false;
    },

    beforeAvatarUpload() {
      this.uploading = true;
    },
    async onJoin() {
      if (this.applyForm.nickname.trim() === '') {
        this.$message({ message: '请输入昵称', type: 'warning' });
        return;
      }
      try {
        await joinHelpUsersApi(this.applyForm)
        this.$message({ message: '申请成功', type: 'success' });
        this.$router.go(-1);
      } catch (error) {
        this.$message({ message: error.msg ? error.msg : '服务器错误', type: 'error' });
      }

    }
  }
}
</script>
<style scoped lang="scss">
.layout-create-center {
  padding: 0px;
  background-color: #F9F8FF;
  position: relative;

  .header-box {
    .icon {
      display: block;
      width: 100%;
      height: 108px;
    }
  }
}

.container {
  width: 1130px;
  margin: 45px auto 0 auto;


  .label-box {
    width: 100%;
    height: 300px;
    background-image: url('../../../static/imgs/create-bg.png');
    background-size: 100% 100%;
    color: #fff;
    padding: 30px;
    overflow-y: scroll;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 36px;
    box-sizing: border-box;
  }

  .form-box {
    margin-top: 30px;

    .content {
      background-color: #fff;
      padding: 30px 0px;
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .logo {
        .avatar-uploader {
          width: 120px;
          height: 120px;
          position: relative;

          .header-img {
            width: 120px;
            height: 120px;
          }

          .btn-edit {
            position: absolute;
            right: 10px;
            bottom: 10px;
            z-index: 9;
            width: 26px;
            height: 26px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
        }
      }

      .form {
        padding-left: 20px;

        .inp {
          width: 450px;
        }

        .btn-box {
          display: flex;
          justify-content: end;

          .btn {
            width: 120px;
            height: 38px;
            background: linear-gradient(131deg, #9854EC 0%, #4E58EC 53%, #145BEC 100%);
            border-radius: 4px 4px 4px 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }

  }
}
</style>
<template>
	<el-aside class="layout-draw-left" width="315px">
		<div class="tab-content-box">
			<div :class="['item', { active: _tabActive === 'create' }]" @click="onTabClick('create')">创建绘画</div>
			<div :class="['item', { active: _tabActive === 'history' }]" @click="onTabClick('history')">历史画板</div>
		</div>
		<div class="title">图像描述</div>
		<div class="pr14">
			<el-input
				type="textarea"
				:rows="4"
				placeholder="请输入你想生成的图像描述"
				v-model="userPrompt"
				resize="none"
			></el-input>
		</div>

		<div class="title mt20">参考图片 (可选)</div>
		<div>
			<el-upload
				list-type="picture-card"
				:limit="1"
				class="avatar-uploader"
				:class="imageUrl ? 'hasImg' : ''"
				name="img"
				action="https://ai-api.deepcity.cn/user/api.userinfo/uploadImg"
				:on-remove="handleImageRemoved"
				:on-success="handleAvatarSuccess"
			>
				<i v-if="!imageUrl" class="el-icon-plus avatar-uploader-icon"></i>
			</el-upload>
		</div>

		<div class="title mt20">模式选择 :</div>
		<div>
			<!-- <el-radio-group v-model="drawMode" size="small">
				<el-radio-button label="专业模式"></el-radio-button>
				<el-radio-button label="普通模式"></el-radio-button>
			</el-radio-group> -->
			<el-select v-model="drawMode" placeholder="请选择">
				<el-option v-for="item in drawLabels" :key="item.label" :label="item.label" :value="item.label">
				</el-option>
			</el-select>
		</div>

		<div class="title mt20">绘画风格 :</div>
		<div class="flex">
			<div
				@click="chooseDrawStyle(idx)"
				class="draw-style"
				:class="drawStyleIndex == idx ? 'actived' : ''"
				v-for="(item, idx) of drawStyles"
				:key="idx"
			>
				<el-image :src="item.image" fit="fill"></el-image>
				<div class="title-box">
					{{ item.title }}
				</div>
			</div>
		</div>

		<div v-show="drawMode == '普通模式'">
			<div class="title mt20 cla">图片大小 :</div>
			<div class="image-size flex">
				<div
					:class="idx == drawSizeIndex ? 'actived' : ''"
					@click="chooseDrawSizeIndex(idx)"
					class="bili-item img-item"
					v-for="(size, idx) of imageSizes"
					:key="idx"
				>
					<div>
						<!-- <div>
							<i class="iconfont icon-images"></i>
						</div> -->
						<div class="mt2">{{ size }}</div>
					</div>
				</div>
			</div>
		</div>

		<div v-show="drawMode == '普通模式'">
			<div class="title mt20 cla">生成数量 :</div>
			<div class="image-num flex">
				<div
					:class="num == drawNum ? 'actived' : ''"
					@click="chooseDrawNum(num)"
					class="bili-item"
					v-for="(num, idx) of imageNums"
					:key="idx"
				>
					<div>
						<span>{{ num }} 张</span>
					</div>
				</div>
			</div>
		</div>

		<div v-show="drawMode == '专业模式'">
			<div class="title mt20 cla">比例 :</div>

			<div class="image-bili flex flex-wrap">
				<div class="flex">
					<div
						@click="chooseDrawBili(idx)"
						:class="drawBiLiIndex == idx ? 'actived' : ''"
						class="bili-item"
						v-for="(b, idx) of imageBLs.slice(0, 5)"
						:key="idx"
					>
						<div class="box" :style="{ width: b.w, height: b.h }"></div>
						<div>{{ b.bl }}</div>

						<!-- <div>{{ b.name }}</div> -->
					</div>
				</div>

				<div class="flex w-100 pr14">
					<div class="bili-item" :class="drawBiLiIndex == 5 ? 'actived' : ''" @click="chooseDrawBili(5)">
						<div class="box" :style="{ width: '10px', height: '18px' }"></div>
						<div>16 : 9</div>
						<!-- <div>电脑壁纸</div> -->
					</div>
					<div class="custom-item" @click="chooseDrawBili(-1)" :class="drawBiLiIndex == -1 ? 'actived' : ''">
						<div clsss="lable">自定义</div>
						<div class="inp-box">
							<el-input v-model="drawCustomBlX"></el-input>
							:
							<el-input v-model="drawCustomBlY"></el-input>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-show="drawMode == '专业模式'">
			<div class="title mt20 cla flex space-between pr18">
				<div @click="showProInfo">
					专业参数 : <a><i class="iconfont icon-info-circle"></i></a>
				</div>
				<el-switch v-model="isOpenProfessional"></el-switch>
			</div>

			<div class="pr16" v-show="isOpenProfessional">
				<el-input
					type="textarea"
					:rows="4"
					placeholder="请输入专业参数"
					resize="none"
					v-model="professional"
				></el-input>
			</div>
		</div>

		<div class="mt20">
			<el-button
				v-if="_status == '0' || _status == '2'"
				class="startDraw"
				size="medium"
				@click="startDraw"
				type="primary"
				>生成图片（ 消耗1个点算力 ）
			</el-button>
			<el-button v-if="_status == '1'" class="startDraw" size="medium" type="primary">正在生成图片...</el-button>
		</div>

		<pro-examples-info ref="proinfo"></pro-examples-info>
	</el-aside>
</template>

<script>
import ProExamplesInfo from '@/views/app/dialogs/pro-examples-info.vue';
import { generateImage, getDrawStyles, getExampleInfo, getGenerateImage } from '@/apis/draw';

export default {
	name: 'layout-draw-left',
	components: { ProExamplesInfo },

	async mounted() {
		this.drawStyles = await getDrawStyles();
		let { imageExampleText, imageExampleUrl } = await getExampleInfo();
		this.exampleInfo.imageExampleText = imageExampleText;
		this.exampleInfo.imageExampleUrl = imageExampleUrl;

		// 等待 order id
		// let waitingOrderId = this.$route.query.wait_order_id;
		// if (waitingOrderId) {
		// 	this.waitingDraw(waitingOrderId);
		// 	this.generateOrderId = waitingOrderId;
		// }
	},

	destroyed() {
		this.intervalId && clearInterval(this.intervalId);
	},

	props: {
		_status: String,
		_newOrderId: String,
		_tabActive: String,
	},
	watch: {
		async _newOrderId(id) {
			this.waitingDraw(id);
			this.generateOrderId = id;
		},
	},
	data() {
		return {
			drawContentIndex: 'create',
			exampleInfo: {},
			drawStyles: [], // 绘图风格,
			drawStyleIndex: 0,
			drawBiLiIndex: 0, // -1 的话就是自定义
			drawNum: 1,
			drawSizeIndex: 0,

			drawCustomBlX: 1,
			drawCustomBlY: 1,

			imageUrl: '',
			userPrompt: '',
			drawMode: '专业模式',
			drawLabels: [{ label: '专业模式' }, { label: '普通模式' }],
			professional: '',
			isOpenProfessional: false,
			imageSizes: ['1024*1024', '512*512', '256*256'],
			imageNums: [1, 2, 4],
			imageBLs: [
				{ name: '头像 ', bl: '1 : 1', w: '18px', h: '18px' },
				{ name: '文章配图 ', bl: '3 : 2', w: '18px', h: '12px' },
				{ name: '社交媒体', bl: '3 : 4', w: '14px', h: '18px' },
				{ name: '公众号配图', bl: '4 : 3', w: '18px', h: '14px' },
				{ name: '海报图', bl: '9 : 16', w: '10px', h: '18px' },
				{ name: '电脑壁纸', bl: '16 : 9', w: '18px', h: '10px' },
			],
			generateOrderId: '',
			imageGenerating: false,
			intervalId: null,
		};
	},

	methods: {
		onTabClick(index) {
			// this.drawContentIndex = index;
			this.$emit('changeContent', index);
		},
		chooseDrawStyle(idx) {
			this.drawStyleIndex = idx;
		},

		chooseDrawBili(idx) {
			this.drawBiLiIndex = idx;
		},

		chooseDrawNum(num) {
			this.drawNum = num;
		},

		chooseDrawSizeIndex(idx) {
			this.drawSizeIndex = idx;
		},

		_getImageSize() {
			if (this.drawMode == '普通模式') {
				return this.imageSizes[this.drawSizeIndex];
			} else {
				if (this.drawBiLiIndex > -1) {
					return this.imageBLs[this.drawBiLiIndex].bl.replace(/\s/g, '');
				} else {
					return this.drawCustomBlX + ':' + this.drawCustomBlY;
				}
			}
		},

		async startDraw() {
			// this.imageGenerating = true

			let copywritingCategoryId = this.drawStyles[this.drawStyleIndex].id;
			let params = {
				model: this.drawMode == '普通模式' ? 'replicateSdxl' : 'midjourney',
				copywritingCategoryId,
				userPrompt: this.userPrompt,
				imageNum: this.drawMode == '普通模式' ? this.drawNum : 1,
				imgUrl: this.imageUrl,
				imageSize: this._getImageSize(),
				isOpenProfessional: this.isOpenProfessional == true ? 1 : 0,
				professional: this.professional,
			};

			try {
				let res = await generateImage(params);
				if (res.code == 0) {
					this.generateOrderId = res.data;
					this.$emit('updateConfig', params);
					this.waitingDraw(res.data);
				} else {
					this.$message({
						message: '生成错误',
						type: 'info',
					});
				}
			} catch (error) {
				console.log(error, 'errorerror');
			}
		},

		// 等待 waiting ...
		waitingDraw(orderId) {
			this.$emit('changeStatus', '1');
			this.intervalId = setInterval(async () => {
				let { images } = await getGenerateImage(orderId);
				if (images && images.length > 0) {
					clearInterval(this.intervalId);
					this.$emit('showImages', images);
					this.$emit('changeStatus', '2');
				}
			}, 5000);
		},

		showProInfo() {
			this.$refs.proinfo.show();
		},

		handleAvatarSuccess({ code, data }) {
			if (code == 0) {
				// 上传成功
				this.imageUrl = data;
			} else {
			}
		},

		handleImageRemoved() {
			this.imageUrl = '';
		},

		beforeAvatarUpload() {},
	},
};
</script>

<style lang="scss">
.layout-draw-left {
	height: calc(100vh - 60px);
	overflow-y: scroll;
	overflow-x: hidden;
	border-right: solid 1px var(--border-color);
	padding: 20px;

	.tab-content-box {
		width: 100%;
		height: 40px;
		border-radius: 8px;
		display: flex;
		margin-bottom: 30px;
		.item {
			width: 50%;
			flex-shrink: 0;
			color: #999999;
			background-color: #f6f7f9;
			cursor: pointer;
			line-height: 40px;
			text-align: center;
			border-radius: 8px;
			font-size: 14px;
			&.active {
				background-color: #6126f5;
				color: #fff;
			}
		}
	}

	.title {
		font-size: 13px;
		font-weight: bold;
		//color: var(--primary);
		margin-bottom: 10px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}

	.avatar {
		width: 100px;
		height: 100px;
		display: block;
	}

	.style-title {
		font-size: 12px;
		text-align: center;
	}

	.draw-style {
		margin-right: 10px;
		width: 80px;
		height: 80px;
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		border: 1px solid #fff;
		cursor: pointer;

		.title-box {
			width: 100%;
			height: 21px;
			line-height: 21px;
			position: absolute;
			left: 0;
			bottom: 0;
			color: #726d6d;
			background-color: rgba(255, 255, 255, 0.6); /* 半透明背景 */
			backdrop-filter: blur(10px);
			text-align: center;
			font-size: 10px;
		}

		&.actived {
			border: 1px solid var(--primary);
		}
	}

	.startDraw {
		width: 100%;
	}

	// card 选择 ...
	.el-card:hover {
		border: 2px solid var(--primary) !important;
	}

	.el-card.actived {
		border: 2px solid var(--primary) !important;
	}

	.image-size {
		.el-card {
			width: 60px;
			height: 60px;
		}

		.el-card__body {
			padding: 0;
			text-align: center;
			vertical-align: middle;
			font-size: 12px;
		}
	}

	.image-num .el-card__body {
		padding-top: 15px !important;
	}

	.image-num,
	.image-size,
	.image-bili {
		font-size: 12px;

		.custom-item {
			padding: 6px 20px;
			box-sizing: border-box;
			margin-bottom: 6px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 4px;
			background-color: #f6f7f9;
			border: 1px solid #f6f7f9;
			border-radius: 4px;
			cursor: pointer;
			.inp-box {
				display: flex;
				margin-left: 10px;
			}
			&:hover,
			&.actived {
				border-color: var(--primary);
				// background: var(--primary-light);
				.box {
					border-color: var(--primary);
				}
			}
		}

		.bili-item {
			padding: 6px 0;
			box-sizing: border-box;
			margin-bottom: 6px;
			width: 50px;
			height: 50px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-right: 4px;
			background-color: #f6f7f9;
			border: 1px solid #f6f7f9;
			cursor: pointer;
			border-radius: 4px;
			&.img-item {
				width: auto;
				padding: 0px 10px;
			}

			&:hover,
			&.actived {
				border-color: var(--primary);
				// background: var(--primary-light);
				.box {
					border-color: var(--primary);
				}
			}
			.box {
				background-color: #fff;
				border: 1px solid #fff;
				border: 3px;
				margin-bottom: 3px;
			}
		}

		.el-input {
			input {
				padding: 4px;
				height: 16px;
				width: 40px;
				font-size: 12px;
				text-align: center;
			}
		}

		div {
			text-align: center;
		}
	}

	.hasImg .el-upload--picture-card {
		display: none;
	}

	.el-upload-list__item {
		transition: none !important;
	}

	//.el-upload-list__item,
	//.el-upload--picture-card {
	//    height: 120px !important;
	//    width: 120px !important;
	//    line-height: 120px !important;
	//}

	.el-upload--picture-card {
		width: 120px !important;
		height: 120px !important;
	}

	.el-upload {
		width: 120px !important;
		height: 120px !important;
		line-height: 120px !important;
	}

	.el-upload-list--picture-card .el-upload-list__item {
		width: 120px !important;
		height: 120px !important;
		line-height: 120px !important;
	}

	.el-upload-list--picture-card .el-upload-list__item-thumbnail {
		width: 120px !important;
		height: 120px !important;
		line-height: 120px !important;
	}

	.el-icon-upload-success {
		display: none;
	}
}
</style>

<template>
	<div class="layout-chat-main">
		<div class="chat-header">{{ classDetail.title }}</div>
		<chat-box id="chatbox" ref="chatBox" :_chatDialogs="chatDialogs"></chat-box>
		<div class="chat-input">
			<div class="opt-panel mb4">
				<!-- <el-button @click="cleanDialogs" size="mini" round class="mr4">
					<i class="iconfont el-icon-brush mr4"></i> 清除聊天记录
				</el-button> -->
				<div class="clean-chat-btn mr4" @click="cleanDialogs">清除聊天记录</div>
			</div>
			<div class="chat-input-box">
				<div class="input-box">
					<el-input
						type="textarea"
						@keyup.enter.native="send"
						:rows="2"
						placeholder="请输入内容"
						v-model="msgText"
						resize="none"
					></el-input>
				</div>
				<!-- <el-button type="primary" @click="send" class="send" size="small">发送</el-button> -->
				<div class="send-icon">
					<img class="icon" @click="send" src="../../../static/icon/send-icon.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ChatBox from '@/views/app/layout-chat/chat-box.vue';
import { getClassDetailApi, saveMsgApi, saveToolMsgContentApi } from '@/apis/tools';

export default {
	name: 'layout-tools-main',
	components: { ChatBox },
	async created() {},
	props: {
		toolId: Number,
	},

	data() {
		return {
			msgText: '',
			sendReq: {
				msgId: '',
				content_cate_id: 0,
				background_id: 0,
				isSend: false,
			},
			chatDialogs: [],
			classDetail: {
				title: '',
			},
			timeoutVar: 0,
			msgId: '',
			msgReqParame: {
				role: 'user',
				content: '',
				lastMsgId: '',
				aiModelGuid: '',
			},
			msgReqAiParame: {
				role: 'assistant',
				content: '',
				lastMsgId: '',
				aiModelGuid: '',
			},
			saveTextReq: {
				title: '',
				workResult: '',
				copywritingCategoryId: '',
				msgId: '',
			},
			//判断当前是否在发送消息中
			isNewGlobalMsg: false,
		};
	},

	computed: {},
	watch: {
		async toolId(id) {
			this.sendReq.content_cate_id = id;
			this.chatDialogs = [];
			try {
				let res = await getClassDetailApi({ cateId: id });
				this.classDetail = res.data;
				this.sendReq.isSend = true;
			} catch (error) {}
		},
	},
	methods: {
		cleanDialogs() {
			this.chatDialogs = [];
		},
		async send() {
			this.submitQuestion(this.msgText);
		},
		async submitQuestion(content) {
			if (!this.sendReq.isSend) {
				this.$message({ message: 'AI模型错误或正在生成中，请稍后重试...', type: 'warning' });
				return;
			}
			if (content.trim().length === 0) {
				this.$message({
					message: '请输入问题 ...',
					type: 'warning',
				});
				return;
			}
			if (this.isNewGlobalMsg) {
				this.$message({
					message: 'AI正在回答中 ...',
					type: 'warning',
				});
				return;
			}
			//设置消息发送中状态
			this.isNewGlobalMsg = true;
			this.msgReqParame.content = content;
			this.msgReqParame.aiModelGuid = this.classDetail.ai_model;
			this.saveTextReq.title = content;
			//写入用户发送的消息
			// let newUserMsg = {
			// 	role: 'user',
			// 	content: content,
			// 	msgId: '',
			// };
			// this.msg.push(newUserMsg);
			try {
				// this.msg.push({
				// 	role: 'assistant',
				// 	content: '',
				// 	isNewMsg: true,
				// 	isSuccessData: false,
				// });
				// let msgLen = this.msg.length;
				// this.nowAiMsgIndex = msgLen - 1;
				//更新msgID
				this.msgReqParame.lastMsgId = this.msgId;
				let res = await saveMsgApi(this.msgReqParame);
				this.chatDialogs.push({ msgId: 'new', chatRole: 'user', chatContent: content, imgUrls: [] });
				this.chatDialogs.push({
					msgId: 'new',
					chatRole: 'assistant',
					chatContent: '',
					starting: true,
					finished: false,
				});
				let dialog = this.chatDialogs[this.chatDialogs.length - 1];
				if (res.code !== 0) {
					dialog.starting = false;
					dialog.chatContent = '算力繁忙，请稍候重试';
					return;
				}
				this.msgText = '';
				this.sendReq.msgId = res.data.msgId;
				this.msgId = res.data.msgId;
				// this.createContact();
				this.waitChat(res.data.msgId);
			} catch (error) {
				//出现错误时重置msgid
				this.sendReq.msgId = '';
				// this.msg[this.nowAiMsgIndex].isNewMsg = false;
				let dialog = this.chatDialogs[this.chatDialogs.length - 1];
				dialog.starting = false;
				dialog.chatContent = '算力繁忙，请稍候重试';
				this.isNewGlobalMsg = false;
				this.msgText = '';
				return;
			}
		},
		// async createContact() {
		// 	try {
		// 		let dialog = this.chatDialogs[this.chatDialogs.length - 1];
		// 		let msg = await getAiMsgApi(this.sendReq);
		// 		this.isNewGlobalMsg = false;
		// 		this.saveTextReq.workResult = msg.data;
		// 		this.msgReqAiParame.content = msg.data;
		// 		//更新msgID
		// 		this.msgReqAiParame.lastMsgId = this.msgId;
		// 		dialog.starting = false;
		// 		dialog.chatContent = msg.data;
		// 		let msgId = await saveMsgApi(this.msgReqAiParame);
		// 		//是否开启对话上下文
		// 		if (this.classDetail.able_lunci === 1) {
		// 			this.msgId = msgId.data.msgId;
		// 		}
		// 		this.saveTextReq.msgId = msgId.data.msgId;
		// 		await this.saveToolMsgContent();
		// 	} catch (error) {
		// 		let dialog = this.chatDialogs[this.chatDialogs.length - 1];
		// 		this.isNewGlobalMsg = false;
		// 		dialog.starting = false;
		// 		dialog.chatContent = '算力繁忙，请稍候重试';
		// 	}
		// },
		async waitChat(lastMsgId) {
			let dialog = this.chatDialogs[this.chatDialogs.length - 1];
			let then = this;
			let link = `https://ai-api.deepcity.cn/square/api.chat/sendOpen?content_cate_id=${this.sendReq.content_cate_id}&msgId=${lastMsgId}&aiModelGuid=${this.classDetail.ai_model}`;
			const source = new EventSource(link);
			source.onmessage = async function ({ data }) {
				data && (dialog.starting = false);
				if (data == '[DONE]') {
					// dialog.msgId = await saveChatDialog({
					// 	role: 'assistant',
					// 	content: dialog.chatContent,
					// 	lastMsgId,
					// 	chatLunciGuid: self._chatingId,
					// 	model: self.selectModelKey,
					// });
					dialog.finished = true;
					then.msgReqAiParame.content = dialog.chatContent;
					//更新msgID
					then.msgReqAiParame.lastMsgId = then.msgId;
					then.msgReqAiParame.aiModelGuid = then.classDetail.ai_model;
					let msgId = await saveMsgApi(then.msgReqAiParame);
					//是否开启对话上下文
					if (then.classDetail.able_lunci === 1) {
						then.msgId = msgId.data.msgId;
					}
					then.saveTextReq.msgId = msgId.data.msgId;
					then.isNewGlobalMsg = false;
					// await then.saveToolMsgContent();
					source.close();
				} else {
					dialog.chatContent += data.replace(/\\n/g, '\n');
				}
			};

			source.onopen = function (event) {
				console.log('Connection was opened');
				// dialog.starting = false
			};

			source.onerror = async function (event) {
				// console.log('event : ', event)
				// dialog.chatContent += '[ ERROR ]'
				source.close();
			};
		},
		async saveToolMsgContent() {
			await saveToolMsgContentApi(this.saveTextReq);
		},
		// 滚动到最底部
		toBottom() {
			this.$nextTick(() => {
				let scrollDom = document.getElementById('chatbox');
				scrollDom.scrollTop = scrollDom.scrollHeight;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.layout-chat-main {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	flex: 1;

	.chat-header {
		border-bottom: 1px solid var(--border-color);
		padding: 14px 20px;
	}

	.chat-input {
		border-top: 1px solid var(--border-color);
		padding: 10px 20px;

		.chat-input-box {
			border-radius: 10px;
			border: 1px solid #e1e2e5;
			position: relative;
			display: flex;
			align-items: center;
			padding-right: 10px;

			.input-box {
				::v-deep .el-textarea__inner {
					border: none;
					border-radius: 10px;
					font-size: 12px;
				}

				flex: 1;
			}

			.send-icon {
				cursor: pointer;

				.icon {
					width: 34px;
					height: 34px;
					display: block;
				}
			}
		}
	}

	.chat-box {
		flex: 1 1;
		overflow: auto;
		padding: 10px 0;
	}

	.opt-panel {
		margin-bottom: 10px;
		display: flex;

		// .el-button {
		//   padding: 2px 11px;
		//   font-size: 12px;
		//   font-weight: normal;

		//   i {
		//     font-size: 12px;
		//   }
		// }

		.clean-chat-btn {
			border-radius: 8px;
			border: 1px solid #dee0e4;
			font-size: 12px;
			color: #737b8d;
			cursor: pointer;
			padding: 6px 10px;
			overflow: hidden;
			/* 确保超出容器的文本被隐藏 */
			white-space: nowrap;
			/* 防止文本换行 */
			text-overflow: ellipsis;

			&:hover {
				border-color: #f6f7f9;
				background: #f6f7f9;
			}
		}
	}
}
</style>

<template>
	<div class="layout-draw-main">
		<div class="empty-panel" v-if="_status == '0'">
			<!-- <img width="500px" src="/imgs/empty-design.png" /> -->
			<img class="loding-img" src="@/static/imgs/draw-loading-img.png" />
			<div class="info mt20">AI龙龙，超级AI工作室</div>
		</div>

		<div class="loading-panel ml30" v-if="_status == '1'">
			<div class="loader">
				<div class="dot"></div>
				<div class="dot"></div>
				<div class="dot"></div>
				<div class="dot"></div>
				<div class="dot"></div>
				<div class="mt60 mb20 loading-text">正在生成中，需要大约1-2分钟的时间，请耐心等候</div>
			</div>
		</div>
		<div class="show-panel" v-if="_images.length > 0 && _status == '2'">
			<el-image class="img" v-for="(item, idx) of _images" :key="idx" :src="item" :preview-src-list="[item]">
				<div slot="placeholder" class="image-slot">
					<i class="el-icon-loading"></i>
				</div>
			</el-image>
			<!-- <el-carousel indicator-position="outside" height="512px" width="512px">
				<el-carousel-item v-for="(item, idx) of _images" :key="idx">
					
				</el-carousel-item>
			</el-carousel> -->

			<div class="mt20 opt-panel"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'layout-draw-main',
	props: {
		_images: Array,
		_status: String,
	},
};
</script>

<style lang="scss">
.layout-draw-main {
	width: 100%;
	height: 100%;
	text-align: center;
	margin: auto;
	background-color: #fff;
	padding: 90px 80px;
	box-sizing: border-box;

	.loading-panel,
	.show-panel,
	.empty-panel {
		height: 100%;
		width: 100%;
		// padding-top: 100px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: #f1f5f9;

		.loding-img {
			width: 400px;
			display: block;
		}

		.info {
			font-size: 32px;
			background: linear-gradient(to right, red, blue);
			-webkit-background-clip: text;
			color: transparent;
		}
		.img {
			max-width: 512px;
			height: auto;
		}
	}

	.prompt-info {
		width: 70%;
		margin-top: 40px;
	}

	.el-image {
		//border: 6px solid var(--primary);
		//border-radius: 6px;
	}

	.el-card__body {
		padding: 10px;
	}

	.el-descriptions-item__label {
		width: 100px !important;
	}

	.el-image__error {
		width: 400px;
	}

	// ---- loading ----

	.loader {
		position: absolute;
		top: 50%;
		left: 40%;
		margin-left: 25%;
		transform: translate3d(-50%, -50%, 0);

		padding: 60px;
		border: solid 6px var(--primary);
		border-radius: 20px;
		background: white;
	}

	.loading-text {
		text-align: center;
		font-size: 20px;
		background: linear-gradient(to right, red, blue);
		-webkit-background-clip: text;
		color: transparent;
	}

	.dot {
		width: 24px;
		height: 24px;
		background: #3ac;
		border-radius: 100%;
		display: inline-block;
		animation: slide 1s infinite;
	}

	.dot:nth-child(1) {
		animation-delay: 0.1s;
		background: #32aacc;
	}

	.dot:nth-child(2) {
		animation-delay: 0.2s;
		background: #64aacc;
	}

	.dot:nth-child(3) {
		animation-delay: 0.3s;
		background: #96aacc;
	}

	.dot:nth-child(4) {
		animation-delay: 0.4s;
		background: #c8aacc;
	}

	.dot:nth-child(5) {
		animation-delay: 0.5s;
		background: #faaacc;
	}

	@-moz-keyframes slide {
		0% {
			transform: scale(1);
		}

		50% {
			opacity: 0.3;
			transform: scale(2);
		}

		100% {
			transform: scale(1);
		}
	}

	@-webkit-keyframes slide {
		0% {
			transform: scale(1);
		}

		50% {
			opacity: 0.3;
			transform: scale(2);
		}

		100% {
			transform: scale(1);
		}
	}

	@-o-keyframes slide {
		0% {
			transform: scale(1);
		}

		50% {
			opacity: 0.3;
			transform: scale(2);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes slide {
		0% {
			transform: scale(1);
		}

		50% {
			opacity: 0.3;
			transform: scale(2);
		}

		100% {
			transform: scale(1);
		}
	}
}
</style>

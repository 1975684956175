<template>
	<el-main class="layout-chat">
		<el-container>
			<layout-chat-left
				@reloadList="loadChatList"
				:_chatList="chatList"
				:_chatingId.sync="chatingId"
				@createChat="createChat"
				:_onChatState="onChatState"
			></layout-chat-left>
			<layout-chat-main
				:_chatObj="chatObj"
				:_chatDialogs="chatDialogs"
				@clearChatDialogs="clearChatDialogs"
				:_chatingId="chatingId"
				@changeOnChatState="changeOnChatState"
			></layout-chat-main>
		</el-container>
	</el-main>
</template>

<script>
import LayoutChatLeft from '@/views/app/layout-chat/layout-chat-left.vue';
import LayoutChatMain from '@/views/app/layout-chat/layout-chat-main.vue';
import { createChat, getChatDialogs, getChatList, getNowChat } from '@/apis/chat';

export default {
	name: 'layout-chat',
	components: { LayoutChatMain, LayoutChatLeft },

	async mounted() {
		await this.loadChatList();
		this.chatingId = await getNowChat();
		// this.chatDialogs = await getChatDialogs({chatLunciGuid: this.chatingId})
	},

	data() {
		return {
			input: '',
			chatList: [],
			chatingId: '',
			chatDialogs: [],
			onChatState: true,
		};
	},

	methods: {
		clearChatDialogs() {
			this.chatDialogs = [];
		},

		async loadChatList() {
			this.chatList = await getChatList();
			this.chatList.forEach(item => {
				item.visible = true;
			});
		},

		async createChat() {
			await createChat();
			await this.loadChatList();
			this.chatingId = await getNowChat();
		},
		changeOnChatState(state) {
			this.onChatState = state;
		},
	},

	computed: {
		chatObj() {
			let obj = this.chatList.find(item => item.guid == this.chatingId);
			return obj || null;
		},
	},

	watch: {
		async chatingId(id) {
			this.chatDialogs = [];
			this.$nextTick(async () => {
				let chats = await getChatDialogs({ chatLunciGuid: id });
				chats.forEach(item => {
					item.finished = true;
					if (item.chatRole === 'assistant') {
						if (item.contentType == 'img') {
							item.imgUrls = [item.chatContent];
							item.chatContent = '';
						}
						if (item.contentType == 'video') {
							item.videoUrls = [item.chatContent];
							item.chatContent = '';
						}
					}
				});
				this.chatDialogs = chats;
			});
		},
	},
};
</script>

<style lang="scss">
.layout-chat {
	padding: 0 !important;
}
</style>
